import moment from 'moment';
import { apiFetchInvoices } from '../../../modules/accounting/api';
import { TimespanFilter } from '../../../modules/common/TimespanPeriodFilter';
import { FilterPickerConsumerProps } from './FilterPickerDynamicConsumer';
import { useQuery } from '@tanstack/react-query';

interface InvoiceFilterPickerConsumerProps extends FilterPickerConsumerProps {
  type?: 'incomingInvoice' | 'outgoingInvoice';
}
const InvoiceFilterPickerConsumer: React.FC<
  InvoiceFilterPickerConsumerProps
> = (props) => {
  //#region ------------------------------ Defaults
  const { children, type } = props;
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const timespan = {
    from: moment('2000-01-01'),
    to: moment(),
  } as TimespanFilter;

  const { data } = useQuery({
    queryKey: ['invoices'],
    queryFn: () => apiFetchInvoices(timespan),
    staleTime: 1000 * 60 * 3, // 3 min
  });
  const _invoices = data?.data ?? [];
  const invoices = type
    ? _invoices.filter((invoice) => invoice.type === type)
    : _invoices;
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <>
      {children({
        options: invoices.map(({ invoiceId, number, title }, index) => {
          return {
            key: index,
            value: invoiceId,
            label: `${number} - ${title}`,
            searchValue: `${number} ${title}`,
          };
        }),
      })}
    </>
  );
};

export default InvoiceFilterPickerConsumer;
