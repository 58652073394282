import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Flex from '../../../components/Flex';
import { FolderPermissionAccessRight } from '../../../models/Settings';
import { FolderPermissionValue } from '../../../models/Types';
import { makePrioStyles } from '../../../theme/utils';
import { Select } from '@prio365/prio365-react-library';

const useStyles = makePrioStyles((theme) => ({
  root: {
    color: theme.colors.application.typography.default,
  },
}));

interface FolderPermissionSelectProps {
  field: FolderPermissionAccessRight;
  value: FolderPermissionValue;
  onChange?: (value: FolderPermissionValue) => void;
  showTitle?: boolean;
  disabled?: boolean;
}

export const FolderPermissionSelect: React.FC<FolderPermissionSelectProps> = (
  props
) => {
  const classes = useStyles();
  const { field, value, onChange, showTitle, disabled = false } = props;
  const { t } = useTranslation();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [dropdownWidth, setDropdownWidth] = React.useState<
    number | undefined
  >();

  useEffect(() => {
    if (wrapperRef.current) {
      setDropdownWidth(wrapperRef.current.clientWidth);
    }
  }, [wrapperRef.current?.clientWidth]);

  return (
    <Flex.Row className={classes.root} alignItems="center">
      {showTitle && (
        <Flex.Item flex={1}>
          {t(
            `settings:projectFileSystemStructureEditor.panel.folderPermissions.${field}`
          )}
        </Flex.Item>
      )}
      <Flex.Item flex={1} ref={wrapperRef}>
        <Select<FolderPermissionValue>
          value={value}
          onChange={onChange}
          style={{ width: '100%' }}
          showSearch={false}
          dropdownStyle={{ width: dropdownWidth }}
          disabled={disabled}
        >
          <Select.Option value="none">
            {t(
              'settings:projectFileSystemStructureEditor.panel.folderPermissions.none'
            )}
          </Select.Option>
          <Select.Option value="read">
            {t(
              'settings:projectFileSystemStructureEditor.panel.folderPermissions.read'
            )}
          </Select.Option>
          <Select.Option value="write">
            {t(
              'settings:projectFileSystemStructureEditor.panel.folderPermissions.write'
            )}
          </Select.Option>
        </Select>
      </Flex.Item>
    </Flex.Row>
  );
};

export default FolderPermissionSelect;
