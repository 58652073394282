import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Menu, Tooltip, Typography } from 'antd';

import Flex from '../../../components/Flex';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import {
  getActiveMailFolderId,
  getIsKeyEnabled,
  getLastOpenDriveItemFolder,
  getProject,
  getUserMe,
  RootReducerState,
} from '../../../apps/main/rootReducer';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { TFunction } from 'i18next';
import { makePrioStyles } from '../../../theme/utils';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import { Classes } from 'jss';
import { MailFolderId } from '../../../models/Types';
import { ProjectNewsCounts } from '../../../models/Project';
import { Project } from '../../../models/Project';
import {
  ExtensionUserState,
  ProjectExtensionType,
} from '../../../models/ProjectExtension';
import { getProjectAccess } from '../utils';

const useStyles = makePrioStyles((theme) => ({
  root: {
    overflowY: 'auto',
  },
  menu: {
    background: 'none',
    border: 'none',
    transition: 'padding 0.2s',
  },
  icon: {
    marginRight: theme.old.spacing.unit(1.5),
    width: 18,
  },
  menuItemPlanner: {
    '&:hover .prio-project-menu-external-link': {
      opacity: 1,
    },
  },
  iconPlanner: {
    opacity: 0,
    marginRight: theme.old.spacing.unit(1.5),
    width: 18,
    '-webkit-transition': 'all 0.3s ease-out',
    transition: 'all 0.3s ease-out',
  },
  settingsMenuItem: {
    fontSize: '14px!important',
  },
  settingsMenuItemIcon: {
    marginRight: theme.old.spacing.unit(1.5) + 3,
    width: 18,
  },
  flex1: {
    flex: 1,
  },
  badgeStatus: {
    '& .ant-badge-status-dot': {
      right: 0,
    },
  },
  badge: {
    '& .ant-badge-dot': {
      top: theme.old.spacing.baseSpacing,
      right: theme.old.spacing.baseSpacing,
      boxShadow: '0 0 0 1px transparent',
    },
  },
  navItemText: {
    '&.ant-typography': {
      color: 'unset',
    },
  },
  menuCollapsed: {
    '&.ant-menu:not(.ant-menu-horizontal) .ant-menu-item': {
      padding: `0 ${theme.old.spacing.unit(1.875)}px`,
      borderRadius: 2,
      display: 'flex',
      flexDirection: 'row',
    },
  },
  itemCollapsed: {
    '&.ant-menu-item > .ant-menu-title-content': {
      overflow: 'hidden',
    },
  },
  itemShowBadge: {
    '&.ant-menu-item > .ant-menu-title-content': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
}));

const getProjectExtensionMe = (
  project: Project,
  type: ProjectExtensionType
) => {
  if (!project) {
    return undefined;
  }
  return project.projectExtensionMeDtos?.find(
    (ext) => ext.projectExtensionType === type
  );
};

const isExtensionLoading = (state: ExtensionUserState | undefined) => {
  if (!state) {
    return false;
  }
  return state === 'waitingForAccess' || state === 'waitingForSetup';
};

const isExtensionDisabled = (state: ExtensionUserState | undefined) => {
  if (!state) {
    return false;
  }
  return state !== 'ready';
};

interface ProjectMenuProps {
  projectId: string;
  className?: string;
  selectedProjectId?: string;
  selectedSubModule?: string;
  masterPlanId?: string;
  groupId?: string;
  emailPrefix?: string;
  emailSuffix?: string;
  pathPrefix?: string;
  collapsed?: boolean;
  projectNewsCounts?: ProjectNewsCounts;
  activeTab: string;
}

export const ProjectMenu: React.FC<ProjectMenuProps> = (props) => {
  //#region ------------------------------ Defaults
  const {
    projectId,
    selectedProjectId,
    selectedSubModule,
    emailSuffix,
    emailPrefix,
    pathPrefix,
    collapsed,
    activeTab,
    className,
    projectNewsCounts,
    // oneDrivePath,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const userMe = useSelector(getUserMe);

  const project = useSelector<RootReducerState, Project>((state) =>
    getProject(state, projectId)
  );
  const isFinanceEnabled = useSelector<RootReducerState, boolean>((state) =>
    getIsKeyEnabled(state, 'General_FinanceServicesEnabled')
  );

  const sharedMailboxProjectExtensionMeState = useMemo(() => {
    return getProjectExtensionMe(project, 'sharedMailbox')?.extensionUserState;
  }, [project]);

  const sharePointProjectExtensionMeState = useMemo(() => {
    return getProjectExtensionMe(project, 'sharepoint')?.extensionUserState;
  }, [project]);

  const plannerProjectExtensionMeState = useMemo(() => {
    return getProjectExtensionMe(project, 'planner')?.extensionUserState;
  }, [project]);

  const promanProjectExtensionMeState = useMemo(() => {
    return getProjectExtensionMe(project, 'proman')?.extensionUserState;
  }, [project]);

  const openExperienceProjectExtensionMeState = useMemo(() => {
    return getProjectExtensionMe(project, 'openExperience')?.extensionUserState;
  }, [project]);

  const isSelectedProject = selectedProjectId === projectId;

  const activeMailFolderId = useSelector<RootReducerState, MailFolderId>(
    (state) => getActiveMailFolderId(state, projectId)
  );
  //#endregion

  const lastOpenFolderDriveItemIds = useSelector((state: RootReducerState) =>
    getLastOpenDriveItemFolder(state, projectId)
  );

  const accessRights = useMemo(() => {
    return getProjectAccess(project, userMe, activeTab);
  }, [project, userMe, activeTab]);

  return (
    <Menu
      selectedKeys={isSelectedProject ? [selectedSubModule] : []}
      className={classNames(classes.menu, className, {
        [classes.menuCollapsed]: collapsed,
      })}
      mode="inline"
      inlineIndent={theme.old.components.menuInlineIndent}
    >
      {navItem(
        'summary',
        'tachometer-alt',
        t,
        theme,
        classes,
        projectId,
        pathPrefix,
        collapsed
      )}
      {accessRights.sharedMailboxUser &&
        navItem(
          'mail',
          'envelope',
          t,
          theme,
          classes,
          projectId,
          pathPrefix,
          collapsed,
          projectNewsCounts?.messageUnread ||
            projectNewsCounts?.messageAssignmentUnread
            ? true
            : false,
          activeMailFolderId ?? undefined,
          sharedMailboxProjectExtensionMeState
        )}
      {accessRights.contacts &&
        navItem(
          'contacts',
          'users',
          t,
          theme,
          classes,
          projectId,
          pathPrefix,
          collapsed
        )}
      {accessRights.tasks &&
        navItem(
          'planner',
          'tasks',
          t,
          theme,
          classes,
          projectId,
          pathPrefix,
          collapsed,
          null,
          null,
          plannerProjectExtensionMeState
        )}
      {accessRights.calendar && (
        <Menu.Item
          key="calendar"
          className={classNames(classes.menuItemPlanner, {
            [classes.itemCollapsed]: collapsed,
          })}
          title={
            <>
              <FontAwesomeIcon
                fixedWidth
                icon={['fal', 'calendar']}
                className={classes.icon}
              />
              <Typography.Text className={classes.navItemText}>
                {t('projects:navigation.calendar')}
              </Typography.Text>
            </>
          }
        >
          <a
            href={`https://outlook.office.com/calendar/${emailPrefix}@${emailSuffix}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Flex.Row>
              <Flex.Item flex={1}>
                <FontAwesomeIcon
                  fixedWidth
                  icon={['fal', 'calendar']}
                  className={classes.icon}
                />
                {!collapsed && (
                  <Typography.Text className={classes.navItemText}>
                    {t('projects:navigation.calendar')}
                  </Typography.Text>
                )}
              </Flex.Item>
              <Flex.Item>
                <FontAwesomeIcon
                  fixedWidth
                  icon={['fal', 'external-link-alt']}
                  className={classNames(
                    'prio-project-menu-external-link',
                    classes.iconPlanner
                  )}
                />
              </Flex.Item>
            </Flex.Row>
          </a>
        </Menu.Item>
      )}
      {accessRights.timeRecord &&
        isFinanceEnabled &&
        navItem(
          'timeRecord',
          'user-clock',
          t,
          theme,
          classes,
          projectId,
          pathPrefix,
          collapsed
        )}
      {accessRights.proman &&
        navItem(
          'proman',
          'clipboard-list',
          t,
          theme,
          classes,
          projectId,
          pathPrefix,
          collapsed,
          null,
          null,
          promanProjectExtensionMeState
        )}
      {accessRights.openExperience &&
        navItem(
          'openexperience',
          'building',
          t,
          theme,
          classes,
          projectId,
          pathPrefix,
          collapsed,
          null,
          null,
          openExperienceProjectExtensionMeState
        )}
      {accessRights.groupId &&
        navItem(
          'documents',
          'folder-tree',
          t,
          theme,
          classes,
          projectId,
          pathPrefix,
          collapsed,
          false,
          lastOpenFolderDriveItemIds?.documentsPage &&
            `folder/${lastOpenFolderDriveItemIds?.documentsPage}`,
          sharePointProjectExtensionMeState
        )}
      {accessRights.invoiceControlling &&
        isFinanceEnabled &&
        navItem(
          'invoiceControlling',
          'euro-sign',
          t,
          theme,
          classes,
          projectId,
          pathPrefix,
          collapsed
        )}
      {accessRights.timeRecordControlling &&
        isFinanceEnabled &&
        navItem(
          'timeRecordControlling',
          'user-clock',
          t,
          theme,
          classes,
          projectId,
          pathPrefix,
          collapsed
        )}
      {accessRights.settings &&
        navItem(
          'settings',
          'cog',
          t,
          theme,
          classes,
          projectId,
          pathPrefix,
          collapsed,
          undefined,
          'general'
        )}
    </Menu>
  );
};

export default ProjectMenu;

const extensionStateTooltipWrapper = (props: {
  projectExtensionMeState: ExtensionUserState;
  children: React.ReactNode;
  t: TFunction;
  addInName: string;
}) => {
  const { projectExtensionMeState, children, addInName, t } = props;
  if (isExtensionLoading(projectExtensionMeState)) {
    return (
      <Tooltip
        title={t(
          `projects:navigation.tooltip.projectExtension.${projectExtensionMeState}`,
          {
            addIn: addInName,
          }
        )}
        placement="right"
      >
        {children}
      </Tooltip>
    );
  }
  return <>{children}</>;
};

const navItem = (
  itemKey: string,
  icon: IconName,
  t: TFunction,
  theme: PrioTheme,
  classes: Classes,
  projectId: string,
  urlPrefix?: string,
  collapsed?: boolean,
  showBadge?: boolean,
  suffix?: string,
  projectExtensionMeState?: ExtensionUserState
) => {
  const isLoading = isExtensionLoading(projectExtensionMeState);

  const disabled = isExtensionDisabled(projectExtensionMeState);

  const itemContent = (
    <>
      {extensionStateTooltipWrapper({
        projectExtensionMeState,
        children: (
          <FontAwesomeIcon
            fixedWidth
            icon={['fal', isLoading ? 'spinner-third' : icon]}
            spin={isLoading}
            className={classes.icon}
          />
        ),
        t,
        addInName: t(`projects:navigation.${itemKey}`),
      })}

      {!collapsed && (
        <Typography.Text className={classes.navItemText}>
          {t(`projects:navigation.${itemKey}`)}
        </Typography.Text>
      )}
    </>
  );

  const link = disabled ? (
    itemContent
  ) : (
    <Link
      to={`${urlPrefix ?? ''}${projectId}/${itemKey}${
        suffix ? `/${suffix}` : ''
      }`}
      className={showBadge ? classes.flex1 : undefined}
    >
      {itemContent}
    </Link>
  );

  return (
    <Menu.Item
      key={itemKey}
      className={classNames({
        [classes.itemShowBadge]: !collapsed && showBadge,
      })}
      disabled={disabled}
      title={
        <>
          {icon !== null ? (
            <FontAwesomeIcon
              fixedWidth
              icon={['fal', icon]}
              className={classes.icon}
            />
          ) : (
            <span className={classes.settingsIcon} />
          )}
          <Typography.Text className={classes.navItemText}>
            {t(`projects:navigation.${itemKey}`)}
          </Typography.Text>
        </>
      }
    >
      {collapsed && showBadge ? (
        <Badge
          className={classes.badge}
          dot
          color={theme.old.palette.primaryColor}
        >
          {link}
        </Badge>
      ) : (
        link
      )}
      {!collapsed && showBadge ? (
        <Badge
          className={classes.badgeStatus}
          dot
          color={theme.old.palette.primaryColor}
        ></Badge>
      ) : null}
    </Menu.Item>
  );
};
