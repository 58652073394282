import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makePrioStyles } from '../../../theme/utils';
import { Typography } from 'antd';
import { createSelector } from 'reselect';
import { Project } from '../../../models/Project';
import { ProjectByIdState } from '../../projects/reducers/projects';
import { MailFolder } from '../../../models/MailFolder';
import {
  getMailSettings,
  RootReducerState,
} from '../../../apps/main/rootReducer';
import { MailFolderId, ProjectId } from '../../../models/Types';
import { Message, MessageCenterMessage } from '../../../models/Message';
import Flex from '../../../components/Flex';
import { useTranslation } from 'react-i18next';
import { apiFetchMailFolders, apiFetchSpecialMailFolders } from '../api';
import { getDisplayName } from '../util';
import { updateMailSettings } from '../../userSettings/actions/mailSettings/mail';
import { copyMessageToProject } from '../actions/actionControllers/messageActionController';
import { setMailListNavigationState } from '../actions/actionControllers/mailNavigationActionController';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import { Button, Modal, Select, Toggle } from '@prio365/prio365-react-library';
import moment from 'moment';
import classNames from 'classnames';
import { useQuery } from '@tanstack/react-query';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  modalSelectTitle: {
    color: theme.old.typography.colors.muted,
    fontSize: theme.font.fontSize.extraSmall,
    marginBottom: theme.old.spacing.unit(0.5),
  },
  modalSelect: {
    overflow: 'hidden',
  },
  label: {
    color: theme.colors.application.typography.muted,
    fontSize: theme.font.fontSize.extraSmall,
  },
  info: {
    color: theme.colors.application.typography.muted,
    fontSize: theme.font.fontSize.small,
  },
  mailContainer: {
    maxHeight: '200px',
    overflowY: 'auto',
  },
  message: {
    fontSize: theme.font.fontSize.small,
  },
  subject: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  warningPanel: {
    padding: `${theme.spacing.small}px ${theme.spacing.regular}px`,
    background: theme.colors.base.yellow[50],
  },
}));

const myProjectsSelector = createSelector<
  [
    (state: RootReducerState) => string[],
    (state: RootReducerState) => ProjectByIdState,
  ],
  Project[]
>(
  (state) => state.projects.projects.myIds,
  (state) => state.projects.projects.byId,
  (ids, byId) =>
    (ids ?? [])
      .map((id) => byId[id])
      .sort((a: Project, b: Project) => {
        return a.name.localeCompare(b.name);
      })
);

function useMailFolders(selectedProjectId: ProjectId): [MailFolder[], boolean] {
  const [mailFolders, setMailFolders] = useState<MailFolder[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  useEffect(() => {
    const fetchFolders = async () => {
      try {
        setIsFetching(true);
        const { data } = await apiFetchMailFolders(selectedProjectId);
        if (data) {
          setMailFolders(data);
        }
      } catch {}
      setIsFetching(false);
    };
    fetchFolders();
  }, [selectedProjectId]);
  return [mailFolders, isFetching];
}

interface MoveMessageModalProps {
  movedMessage: Message;
  selectedMessages: Message[];
  projectId: ProjectId;
  defaultSelected: ProjectId;
  mailFolderId?: MailFolderId;
  onOk: VoidFunction;
  onCancel: VoidFunction;
  onSelectionChange?: (selectedIds: Message[]) => void;
}

export const MoveMessageModal: React.FC<MoveMessageModalProps> = (props) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();

  const {
    movedMessage,
    projectId,
    mailFolderId,
    selectedMessages,
    defaultSelected,
    onOk,
    onCancel,
    onSelectionChange,
  } = props;

  const dispatch = useDispatch();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const messageContainerRef = useRef<HTMLDivElement>(null);
  const messagesToCopy = useMemo(() => {
    const messages = (
      selectedMessages?.find(
        (selectedMessage) => selectedMessage.id === movedMessage.id
      )
        ? selectedMessages
        : [movedMessage]
    ) as MessageCenterMessage[];
    return messages;
  }, [selectedMessages, movedMessage]);

  const [messagesToIgnore, setMessagesToIgnore] = useState<
    MessageCenterMessage[]
  >([]);

  const [messagesThatAlreadyExist, setMessagesThatAlreadyExist] = useState<
    MessageCenterMessage[]
  >([]);

  const relevantMessagesToCopy = useMemo(
    () =>
      messagesToCopy.filter(
        (message) =>
          // we cannot filter by id because copy message has different id
          !messagesToIgnore.find(
            (m) =>
              m.subject === message.subject &&
              m.sender.emailAddress.address ===
                message.sender.emailAddress.address &&
              m.receivedDateTime === message.receivedDateTime
          )
      ),
    [messagesToCopy, messagesToIgnore]
  );

  const modalTitle = `${
    relevantMessagesToCopy.length > 1
      ? t('mail:widgetArea.modal.titlePlural', {
          number: relevantMessagesToCopy.length,
        })
      : t('mail:widgetArea.modal.title')
  } `;

  const isScrollbarVisible =
    (messageContainerRef.current?.offsetWidth ?? 0) >
    (messageContainerRef.current?.clientWidth ?? 0);

  const noRelevantMessagesToCopy = relevantMessagesToCopy.length === 0;

  const settings = useSelector(getMailSettings);

  const myProjects = useSelector(myProjectsSelector);

  const [selectedProjectId, setSelectedProjectId] =
    useState<ProjectId>(defaultSelected);

  const [mailFolders, isFetching] = useMailFolders(selectedProjectId);

  const [selectedMailFolderId, setSelectedMailFolderId] =
    useState<MailFolderId>(null);

  const { data: { data: specialMailFolders } = {} } = useQuery({
    queryKey: [selectedProjectId, 'specialMailFolders'],
    queryFn: () => apiFetchSpecialMailFolders(selectedProjectId),
    staleTime: 1000 * 60 * 60 * 20, // 20 hour
  });
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleOnOk = async (checkForDuplicates, hideDuplicateMessages) => {
    try {
      const destinationMailFolderId =
        selectedMailFolderId === specialMailFolders['inboxFolder']?.id
          ? 'inbox'
          : selectedMailFolderId;
      const messagesByProjectId: { [projectId: ProjectId]: Message[] } =
        relevantMessagesToCopy.reduce(
          (map, message) => ({
            ...map,
            [message.projectId ?? projectId]: [
              ...(map[message.projectId ?? projectId] ?? []),
              message,
            ],
          }),
          {}
        );
      Object.keys(messagesByProjectId).forEach((messageProjectId) =>
        dispatch(
          copyMessageToProject(
            messagesByProjectId[messageProjectId],
            messageProjectId,
            mailFolderId ?? 'inbox',
            destinationMailFolderId,
            selectedProjectId,
            settings.deleteMovedMessageMe,
            checkForDuplicates,
            hideDuplicateMessages
          )
        )
      );
      if (settings.deleteMovedMessageMe) {
        const urlId = !!window.location.href.match(/message\/(.*)\/details?$/)
          ? window.location.href.match(/message\/(.*)\/details?$/)[1]
          : null;
        window.history.replaceState(
          {},
          '',
          urlId &&
            relevantMessagesToCopy.find((message) => message.id === urlId)
            ? `../../../${mailFolderId}`
            : ''
        );
        if (relevantMessagesToCopy.find((message) => message.id === urlId)) {
          dispatch(setMailListNavigationState(null, projectId));
        }
      }
    } catch (e) {
      console.warn('some error occurred loading personal messages', e);
    }
    if (
      onSelectionChange &&
      selectedMessages?.find(
        (selectedMessage) => selectedMessage.id === movedMessage.id
      )
    ) {
      onSelectionChange([]);
    }
    onOk();
  };

  const handleOnClose = () => {
    setMessagesThatAlreadyExist([]);
    setMessagesToIgnore([]);
    onCancel();
  };

  const handleOnRemoveDuplicate = (messages: MessageCenterMessage[]) => {
    setMessagesToIgnore((prev) => [...prev, ...messages]);
    setMessagesThatAlreadyExist((prev) =>
      prev.filter(
        (m) => m?.id !== messages.find((mes) => mes?.id === m?.id)?.id
      )
    );
  };
  //#endregion

  //#region ------------------------------ Effects

  useEffect(() => {
    if (mailFolders.find((folder) => folder.id === mailFolderId)) {
      setSelectedMailFolderId(
        mailFolderId && mailFolderId !== 'inbox'
          ? mailFolderId
          : mailFolders.find(
              (folder) =>
                specialMailFolders &&
                folder.id === specialMailFolders['inboxFolder']?.id
            )?.id ?? null
      );
    } else {
      setSelectedMailFolderId(
        specialMailFolders
          ? specialMailFolders['inboxFolder']?.id ?? null
          : null
      );
    }
  }, [mailFolders, mailFolderId, specialMailFolders]);

  useEffect(() => {
    setSelectedProjectId(defaultSelected);
  }, [defaultSelected]);
  //#endregion

  return (
    <Modal
      title={modalTitle}
      cancelText={t('mail:widgetArea.modal.cancelText')}
      onClose={handleOnClose}
      footer={[
        <Button
          type="primary"
          disabled={noRelevantMessagesToCopy}
          onClick={() => handleOnOk(true, true)}
          dropdownOptions={[
            {
              label: t('mail:widgetArea.modal.moveWithDuplicates'),
              value: 'withDuplicates',
              onClick: () => handleOnOk(false, true),
              disabled: noRelevantMessagesToCopy,
            },
            {
              label: t('mail:widgetArea.modal.moveCaseByCase'),
              value: 'caseByCase',
              onClick: () => handleOnOk(true, false),
              disabled: noRelevantMessagesToCopy,
            },
          ]}
        >
          {t('mail:widgetArea.modal.moveWithoutDuplicates')}
        </Button>,
        <Button key="cancel" onClick={handleOnClose} type="default">
          {t('mail:widgetArea.modal.cancelText')}
        </Button>,
      ]}
      visible={!!movedMessage}
      width="600px"
    >
      <Flex.Column childrenGap={theme.old.spacing.defaultPadding}>
        {noRelevantMessagesToCopy && (
          <div className={classNames(classes.warningPanel, classes.label)}>
            {t('mail:widgetArea.modal.warning')}
          </div>
        )}
        {messagesThatAlreadyExist.length > 0 && (
          <Flex.Column childrenGap={theme.spacing.small}>
            <div className={classes.info}>
              {t('mail:widgetArea.modal.label')}
            </div>
            <Flex.Row alignItems="center" className={classes.label}>
              <Flex.Item flex={2}>
                {t('mail:widgetArea.modal.sender')}
              </Flex.Item>
              <Flex.Row
                flex={4}
                alignItems="center"
                justifyContent="space-between"
              >
                <Flex.Item>
                  {t('mail:widgetArea.modal.subjectAndTime')}
                </Flex.Item>
                {messagesThatAlreadyExist.length > 1 && (
                  <Button
                    size="small"
                    type="link"
                    iconProp={['fal', 'xmark']}
                    tooltip={t('mail:widgetArea.modal.removeAllDuplicates')}
                    onClick={() =>
                      handleOnRemoveDuplicate(messagesThatAlreadyExist)
                    }
                    style={isScrollbarVisible ? { marginRight: '6px' } : {}}
                  ></Button>
                )}
              </Flex.Row>
            </Flex.Row>
            <Flex.Column
              ref={messageContainerRef}
              className={classes.mailContainer}
              childrenGap={theme.spacing.small}
            >
              {messagesThatAlreadyExist.map((message) => (
                <Flex.Row alignItems="center" className={classes.message}>
                  <Flex.Item flex={2}>
                    {message.sender.emailAddress.name ??
                      message.sender.emailAddress.address}
                  </Flex.Item>
                  <Flex.Row
                    flex={4}
                    alignItems="center"
                    justifyContent="space-between"
                    childrenGap={theme.spacing.extraSmall}
                  >
                    <div>{`${message.subject} (${moment(
                      message.sentDateTime
                    ).format('DD.MM.YYYY HH:MM')})`}</div>
                    <Button
                      size="small"
                      type="link"
                      iconProp={['fal', 'xmark']}
                      tooltip={t('mail:widgetArea.modal.removeDuplicate')}
                      onClick={() => handleOnRemoveDuplicate([message])}
                    ></Button>
                  </Flex.Row>
                </Flex.Row>
              ))}
            </Flex.Column>
          </Flex.Column>
        )}
        <Flex.Row childrenGap={theme.old.spacing.defaultPadding}>
          <Flex.Column className={classes.modalSelect} flex={1}>
            <Typography.Text className={classes.modalSelectTitle}>
              {t('mail:widgetArea.modal.project')}
            </Typography.Text>
            <Select
              value={selectedProjectId}
              onChange={(projectId) => setSelectedProjectId(projectId)}
              disabled={noRelevantMessagesToCopy}
            >
              {myProjects.map((project) => (
                <Select.Option
                  value={project.projectId}
                  key={project.projectId}
                >
                  {` ${project.number} ${project.shortName}`}
                </Select.Option>
              ))}
            </Select>
          </Flex.Column>
          <Flex.Column className={classes.modalSelect} flex={1}>
            <Typography.Text className={classes.modalSelectTitle}>
              {t('mail:widgetArea.modal.mailFolder')}
            </Typography.Text>
            <Select
              value={isFetching ? null : selectedMailFolderId}
              onChange={(mailFolderId) => setSelectedMailFolderId(mailFolderId)}
              loading={isFetching}
              disabled={noRelevantMessagesToCopy}
            >
              {specialMailFolders &&
                mailFolders.map((mailFolder) => (
                  <Select.Option value={mailFolder.id} key={mailFolder.id}>
                    {getDisplayName(mailFolder, specialMailFolders, t)}
                  </Select.Option>
                ))}
            </Select>
          </Flex.Column>
        </Flex.Row>
        <Toggle
          checked={settings.deleteMovedMessageMe}
          onChange={(value) =>
            dispatch(
              updateMailSettings({
                deleteMovedMessageMe: value,
              })
            )
          }
          disabled={noRelevantMessagesToCopy}
        >
          {t('mail:widgetArea.modal.deleteOrigin')}
        </Toggle>
      </Flex.Column>
    </Modal>
  );
};

export default MoveMessageModal;
