import { ApiResult } from '../../../api';
import { ReportCollections } from '../../../models/ProjectExtension/Proman';

export const apiGetPromanReportCollections: (
  aPromanProjectId: number
) => Promise<ApiResult<ReportCollections>> = async (aPromanProjectId) => {
  // const result = await fetchWithRetry(
  //   `${apiUrl}/integration/Proman/${aPromanProjectId}/ReportCollections`,
  //   {
  //     headers: { Authorization: `Bearer ${await getAccessToken()}` },
  //   }
  // );
  // const data =
  //   result.status >= 200 && result.status < 300 ? await result.json() : null;
  // return {
  //   result,
  //   data,
  // };

  // TODO: Change this when backend is ready
  return {
    result: null,
    data: {
      ReportCollections: [
        {
          ReportCollectionId: 1,
          GlobalReportTitle: 'Interne Protokolle',
          CollectionLong: 'Interne Protokolle der Firma',
          CollectionShort: 'Interne Protokolle',
          FolderID: 101,
          ProjectId: 1001,
          LastModifiedDate: '2023-04-01T12:00:00Z',
          DeletionDate: null,
          IsDeleted: 'false',
          ExternalId: 'ext-12345',
          Reports: [
            {
              ReportId: 11,
              ReportCode: 'RPT-001',
              MeetingDate: '2023-05-01T10:00:00Z',
              ReportStatusModel: {
                ReportStatusID: 1,
                StatusType: 'Final',
                LastModifiedDate: '2023-05-01T12:00:00Z',
                DeletionDate: null,
                IsDeleted: 'false',
                ExternalId: 'ext-56789',
              },
              MeetingPlace: 'Conference Room A',
              ReportCollectionID: 1,
              ProjectId: 1001,
              LastModifiedDate: '2023-05-01T12:00:00Z',
              DeletionDate: null,
              IsDeleted: 'false',
              ExternalId: 'ext-56789',
              Documents: [
                {
                  ID: 1,
                  DocumentType: 'PDF',
                  PrintType: 'All',
                  Description: 'Meeting Report',
                  FileSize: 1024,
                  FileName: 'meeting-report.pdf',
                  DownloadUrl: 'https://pdfobject.com/pdf/sample.pdf',
                  LastModifiedDate: '2023-05-01T12:00:00Z',
                  DeletionDate: null,
                  IsDeleted: false,
                  ExternalId: 'doc-12345',
                },
              ],
            },
          ],
        },
        {
          ReportCollectionId: 2,
          GlobalReportTitle: 'Externe Protokolle',
          CollectionLong: 'Externe Protokolle der Firma',
          CollectionShort: 'Externe Protokolle',
          FolderID: 102,
          ProjectId: 1002,
          LastModifiedDate: '2023-04-02T12:00:00Z',
          DeletionDate: null,
          IsDeleted: 'false',
          ExternalId: 'ext-12346',
          Reports: [
            {
              ReportId: 2,
              ReportCode: 'RPT-002',
              MeetingDate: '2023-06-01T10:00:00Z',
              ReportStatusModel: {
                ReportStatusID: 2,
                StatusType: 'Draft',
                LastModifiedDate: '2023-06-01T12:00:00Z',
                DeletionDate: null,
                IsDeleted: 'false',
                ExternalId: 'ext-56790',
              },
              MeetingPlace: 'Conference Room B',
              ReportCollectionID: 2,
              ProjectId: 1002,
              LastModifiedDate: '2023-06-01T12:00:00Z',
              DeletionDate: null,
              IsDeleted: 'false',
              ExternalId: 'ext-56790',
              Documents: [
                {
                  ID: 2,
                  DocumentType: 'PDF',
                  PrintType: 'All',
                  Description: 'Draft Report',
                  FileSize: 2048,
                  FileName: 'draft-report.pdf',
                  DownloadUrl: 'https://pdfobject.com/pdf/sample.pdf',
                  LastModifiedDate: '2023-06-01T12:00:00Z',
                  DeletionDate: null,
                  IsDeleted: false,
                  ExternalId: 'doc-12346',
                },
              ],
            },
          ],
        },
        {
          ReportCollectionId: 3,
          GlobalReportTitle: 'Berichte',
          CollectionLong: 'Berichte der Firma',
          CollectionShort: 'Berichte',
          FolderID: 103,
          ProjectId: 1003,
          LastModifiedDate: '2023-04-03T12:00:00Z',
          DeletionDate: null,
          IsDeleted: 'false',
          ExternalId: 'ext-12347',
          Reports: [
            {
              ReportId: 3,
              ReportCode: 'RPT-003',
              MeetingDate: '2023-07-01T10:00:00Z',
              ReportStatusModel: {
                ReportStatusID: 3,
                StatusType: 'In Progress',
                LastModifiedDate: '2023-07-01T12:00:00Z',
                DeletionDate: null,
                IsDeleted: 'false',
                ExternalId: 'ext-56791',
              },
              MeetingPlace: 'Conference Room C',
              ReportCollectionID: 3,
              ProjectId: 1003,
              LastModifiedDate: '2023-07-01T12:00:00Z',
              DeletionDate: null,
              IsDeleted: 'false',
              ExternalId: 'ext-56791',
              Documents: [],
            },
          ],
        },
      ],
      Success: true,
      ErrorMessage: '',
    },
  };
};
