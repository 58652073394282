import React, { useEffect, useState } from 'react';
import { ProjectId } from '../../../models/Types';
import { makePrioStyles } from '../../../theme/utils';
import Flex from '../../../components/Flex';
import ProjectMailSettingsManagement from './ProjectMailSettingsManagement';
import { apiFetchEmailProjectSettingPreview } from '../../mail/api';
import { EmailProjectSettingPreview } from '../../../models/Project';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import ProjectMailAssignmentSetting from './ProjectMailAssignmentSetting';

const useStyles = makePrioStyles((theme) => ({
  root: {
    backgroundColor: theme.old.palette.backgroundPalette.sub,
    padding: theme.old.spacing.defaultPadding,
    height: '100%',
    overflowY: 'auto',
  },
  shadow: {
    boxShadow: theme.old.palette.boxShadow.regular,
  },
}));

interface MailSettingsPageProps {
  projectId: ProjectId;
}

export const MailSettingsPage: React.FC<MailSettingsPageProps> = (props) => {
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { projectId } = props;

  const [preview, setPreview] = useState<EmailProjectSettingPreview>(null);

  useEffect(() => {
    const fetchPreviews = async () => {
      const { data } = await apiFetchEmailProjectSettingPreview(projectId, [
        'emailSubject',
        'emailSignature',
      ]);
      if (data) {
        setPreview(data);
      }
    };
    fetchPreviews();
  }, [projectId]);

  return (
    <div className={classes.root}>
      <Flex.Column childrenGap={theme.old.spacing.unit(2)}>
        <ProjectMailAssignmentSetting
          className={classes.shadow}
          projectId={projectId}
        />
        <ProjectMailSettingsManagement
          className={classes.shadow}
          projectId={projectId}
          type="emailSubject"
          initialPreview={preview ? preview['emailSubject'] : null}
        />
        <ProjectMailSettingsManagement
          className={classes.shadow}
          projectId={projectId}
          type="emailSignature"
          initialPreview={preview ? preview['emailSignature'] : null}
        />
      </Flex.Column>
    </div>
  );
};

export default MailSettingsPage;
