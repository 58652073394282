import React, { useState } from 'react';
import { Button, notification } from 'antd';
import { useTranslation } from 'react-i18next';

import { CopyMessageWs, Message } from '../../../../models/Message';
import { ProjectId } from '../../../../models/Types';
import { apiCopyMessageAsync } from '../../../mail/api';

interface DuplicateMessageNotificationProps {
  sourceMessage: Message;
  sourceProjectId: ProjectId;
  copyMessageWs: CopyMessageWs;
  destinationProjectId: ProjectId;
  destinationMailFolder: string;
}

export const DuplicateMessageNotification: React.FC<
  DuplicateMessageNotificationProps
> = ({
  copyMessageWs,
  sourceProjectId,
  destinationProjectId,
  sourceMessage,
  destinationMailFolder,
}) => {
  const copyMessageDto = copyMessageWs.copyMessageDto;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleMoveMessage = async () => {
    setLoading(true);
    try {
      await apiCopyMessageAsync(
        [sourceMessage],
        sourceProjectId,
        destinationMailFolder,
        destinationProjectId,
        copyMessageDto.deleteMail,
        copyMessageDto.copyAttachments,
        false,
        true
      );
    } catch (error) {
      console.error('Error moving message:', error);
    } finally {
      setLoading(false);
      handleCloseNotification();
    }
  };

  const handleCloseNotification = () => {
    notification.close(`duplicateMessage-${copyMessageDto.messageId}`);
  };

  const truncateText = (text: string, maxLength: number) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };

  return (
    <div>
      <div>{`${t('mail:notification.newMessageSubject')}: ${truncateText(
        sourceMessage?.subject || '',
        50
      )}`}</div>
      <div>{`${t('mail:notification.sender')}: ${
        sourceMessage.from?.emailAddress?.name ||
        sourceMessage.from?.emailAddress?.address ||
        ''
      }`}</div>
      <div>{`${t('mail:notification.date')}: ${new Date(
        sourceMessage?.receivedDateTime
      ).toLocaleString()}`}</div>
      <p>{`${t('mail:notification.content')}: ${truncateText(
        sourceMessage.bodyPreview || '',
        100
      )}`}</p>
      <Button onClick={handleCloseNotification}>
        {t('mail:notification.close')}
      </Button>
      <Button
        onClick={handleMoveMessage}
        type="primary"
        style={{ float: 'right' }}
        loading={loading}
      >
        {t('mail:notification.moveMessage')}
      </Button>
    </div>
  );
};

export default DuplicateMessageNotification;
