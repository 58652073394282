import { Form, Switch } from 'antd';
import { Button, Input, Select } from '@prio365/prio365-react-library';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InformationBox from '../../../../../../components/InformationBox';
import Flex from '../../../../../../components/Flex';
import { makePrioStyles } from '../../../../../../theme/utils';
import { apiGetProjectExtensionSuggestion } from '../../../../api';

const useStyles = makePrioStyles((theme) => ({
  root: {
    width: '100%',
    minHeight: '100px',
  },
  label: {
    color: theme.colors.application.typography.muted,
  },
  submitButton: {
    position: 'absolute',
    bottom: '32px',
  },
}));

export interface OpenExperienceProjectExtensionFormModel {
  displayName: string;
  projectKey: string;
  projectType: string;
  services: string[];
  syncExternalContacts: boolean;
  autoAccess: boolean;
  linkOnlyThisProject: boolean;
  duplicateProject: boolean;
  duplicateProjectId: string;
  linkedProject: string;
}

const OpenExperienceProjectExtensionForm = ({
  projectExtension,
  addProjectExtension,
  projectId,
  updateProjectExtension,
  createProjectExtension,
}) => {
  const classes = useStyles();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [projectMode, setProjectMode] = useState('new');
  const [disabled, setDisabled] = useState<boolean>(true);
  // const [duplicateProjectEnabled, setDuplicateProjectEnabled] = useState(false);
  const [projectSuggestions, setProjectSuggestions] = useState([]);

  useEffect(() => {
    const fetchProjectSuggestions = async () => {
      const { data } = await apiGetProjectExtensionSuggestion(projectId, {
        key: null,
        value: null,
        projectExtensionType: 'openExperience',
      });
      if (data) {
        setProjectSuggestions(
          data.availableProjectExtensions.map((proj) => ({
            value: proj.key,
            label: proj.displayName,
          }))
        );
      }
    };
    fetchProjectSuggestions();
  }, [projectId]);

  useEffect(() => {
    form.resetFields();
  }, [projectExtension, form]);

  const handleSubmit = async (values) => {
    setLoading(true);
    if (projectExtension) {
      /*
      await updateProjectExtension(
        projectId,
        projectExtension.projectExtensionId,
        values
      );
      */
    } else {
      await createProjectExtension(projectId, {
        displayName: values.displayName,
        key: values.projectType === 'new' ? '' : values.linkedProject,
        value: values.projectType === 'new' ? values.projectKey : '',
        hasDefaultAccess: false,
        settings: [
          {
            settingKey: 'ProjectExtension_OpenExperience_Settings',
            settingJson: JSON.stringify({
              EnabledServices: values.services,
              DuplicateProjectId: values.referenceProject ?? null,
              SyncExternalContactsAsContractors: values.syncExternalContacts,
            }),
          },
        ],
        projectExtensionType: 'openexperience',
      });
    }
    setLoading(false);
  };

  return (
    <>
      {projectExtension && (
        <>
          <p>{t('projects:addIn.extensions.openExperience.notsupportedyet')}</p>
        </>
      )}
      {!projectExtension && (
        <Form
          form={form}
          layout="vertical"
          className={classes.root}
          initialValues={
            projectExtension || {
              displayName: '',
              projectKey: '',
              projectType: 'new',
              services: [],
              syncExternalContacts: true,
              autoAccess: false,
              linkOnlyThisProject: false,
              duplicateProject: false,
              linkedProject: '',
            }
          }
          onFinish={handleSubmit}
          onValuesChange={(changedValues) => {
            // check if displayname, key and service is filled, but by the form not the changedValues
            console.log('achas');
            if (
              (form.getFieldValue('displayName') &&
                form.getFieldValue('services').length > 0 &&
                form.getFieldValue('projectType') === 'new') ||
              (form.getFieldValue('displayName') &&
                form.getFieldValue('services').length > 0 &&
                form.getFieldValue('projectType') === 'link' &&
                form.getFieldValue('linkedProject'))
            ) {
              setDisabled(false);
            } else {
              setDisabled(true);
            }
          }}
        >
          <Form.Item
            name="displayName"
            label={t(
              'projects:addIn.extensions.openExperience.form.labels.displayName'
            )}
            rules={[{ required: true }]}
          >
            <Input disabled={loading} full />
          </Form.Item>
          <InformationBox
            description={t(
              'projects:addIn.extensions.openExperience.displayNameDescription'
            )}
          />
          <Form.Item
            name="projectType"
            label={t(
              'projects:addIn.extensions.openExperience.form.labels.projectType'
            )}
            rules={[{ required: true }]}
          >
            <Select
              onChange={(value) => setProjectMode(value)}
              defaultValue={'new'}
              showSearch={false}
            >
              <Select.Option value="new">
                {t(
                  'projects:addIn.extensions.openExperience.form.options.newProject'
                )}
              </Select.Option>
              <Select.Option value="link">
                {t(
                  'projects:addIn.extensions.openExperience.form.options.linkProject'
                )}
              </Select.Option>
            </Select>
          </Form.Item>
          <InformationBox
            description={t(
              'projects:addIn.extensions.openExperience.projectTypeDescription'
            )}
          />

          <Form.Item
            name="projectKey"
            label={t(
              'projects:addIn.extensions.openExperience.form.labels.projectKey'
            )}
            hidden={projectMode === 'link'}
            required={projectMode === 'new'}
            rules={[
              {
                // only id new
                required: projectMode === 'new',
                message: t(
                  'projects:addIn.extensions.openExperience.form.validation.projectKeyRequired'
                ),
              },
              {
                pattern: /^[A-Z0-9_-]{1,50}$/,
                message: t(
                  'projects:addIn.extensions.openExperience.form.validation.projectKeyPattern'
                ),
              },
            ]}
          >
            <Input disabled={loading} full />
          </Form.Item>
          {projectMode === 'new' && (
            <InformationBox
              description={t(
                'projects:addIn.extensions.openExperience.projectKeyDescription'
              )}
            />
          )}

          <Form.Item
            name="services"
            label={t(
              'projects:addIn.extensions.openExperience.form.labels.services'
            )}
            rules={[{ required: true }]}
          >
            <Select mode="multiple">
              <Select.Option value="oa">Bau-Mängel</Select.Option>
              <Select.Option value="fd2">Bau-Foto</Select.Option>
              <Select.Option value="qm">Bau-Checklisten</Select.Option>
              <Select.Option value="ot2">Bau-Tagebuch</Select.Option>
            </Select>
          </Form.Item>
          {projectMode === 'new' && (
            <>
              {/*}
          <Form.Item
            name="duplicateProject"
            label={t(
              'projects:addIn.extensions.openExperience.form.labels.duplicateProject'
            )}
            valuePropName="checked"
            required={false}
          >
            <Switch disabled={loading} />
          </Form.Item>
          <InformationBox
            description={t(
              'projects:addIn.extensions.openExperience.duplicateProjectDescription'
            )}
          />
          */}
            </>
          )}

          {projectMode === 'link' && (
            <>
              <Form.Item
                name="linkedProject"
                label={t(
                  'projects:addIn.extensions.openExperience.form.labels.linkedProject'
                )}
              >
                <Select options={projectSuggestions} disabled={loading} />
              </Form.Item>
              <InformationBox
                description={t(
                  'projects:addIn.extensions.openExperience.linkedProjectDescription'
                )}
              />
            </>
          )}

          <Form.Item
            name="syncExternalContacts"
            label={t(
              'projects:addIn.extensions.openExperience.form.labels.syncExternalContacts'
            )}
            valuePropName="checked"
          >
            <Switch disabled={loading} />
          </Form.Item>
          <InformationBox
            description={t(
              'projects:addIn.extensions.openExperience.syncExternalContactsDescription'
            )}
          />

          <Flex.Row flex={1} justifyContent="flex-end">
            <Button
              htmlType="submit"
              disabled={loading || disabled}
              loading={loading}
              className={classes.submitButton}
            >
              {t('projects:addIn.extensions.form.save')}
            </Button>
          </Flex.Row>
        </Form>
      )}
    </>
  );
};

export default OpenExperienceProjectExtensionForm;
