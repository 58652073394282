import projects, * as fromProjects from './projects';
import internalProjectContacts, * as fromInternalProjectContacts from './internalProjectContacts';
import externalProjectContacts, * as fromExternalProjectContacts from './externalProjectContacts';
import hourlyRates, * as fromHourlyRates from './hourlyRates';
import kilometerRates, * as fromKilometerRates from './kilometerRates';
import activeProject, * as fromActiveProject from './activeProject';
import activeProjectTabView, * as fromActiveProjectTabView from './activeProjectTabView';
import lastProjectLocation, * as fromLastProjectLocation from './lastProjectLocation';
import projectDistributionList, * as fromProjectDistributionList from './projectDistributionList';
import projectNews, * as fromProjectNews from './projectNews';
import projectNewsCounts, * as fromProjectNewsCounts from './projectNewsCounts';
import {
  Project,
  ProjectNews,
  ProjectNewsCategoryKey,
  ProjectNewsCounts,
  ProjectsWithNewsCounts,
} from '../../../models/Project';
import { combineReducers } from 'redux';
import { ProjectId, ContactId, ProjectRole } from '../../../models/Types';
import {
  InternalProjectContact,
  ExternalProjectContact,
} from '../../../models/ProjectContacts';
import { HourlyRate } from '../../../models/HourlyRate';
import { KilometerRate } from '../../../models/KilometerRate';
import { ProjectDistributionList } from '../../../models/ProjectDistributionList';

export interface ProjectsReducerState {
  projects: fromProjects.ProjectsState;
  internalProjectContacts: fromInternalProjectContacts.InternalProjectContactsState;
  externalProjectContacts: fromExternalProjectContacts.ExternalProjectContactsState;
  hourlyRates: fromHourlyRates.HourlyRatesState;
  kilometerRates: fromKilometerRates.KilometerRatesState;
  projectDistributionList: fromProjectDistributionList.ProjectDistributionListState;
  projectNews: fromProjectNews.ProjectNewsState;
  projectNewsCounts: fromProjectNewsCounts.ProjectNewsCountState;
  activeProject: string;
  activeProjectTabView: string;
  lastProjectLocation: string;
}

export default combineReducers<ProjectsReducerState>({
  projects,
  internalProjectContacts,
  externalProjectContacts,
  hourlyRates,
  kilometerRates,
  projectDistributionList,
  projectNews,
  projectNewsCounts,
  activeProject,
  activeProjectTabView,
  lastProjectLocation,
});

export const getAllProjects: (state: any) => Array<Project> = (state) =>
  fromProjects.getAllProjects(state.projects);
export const getProject: (state: any, projectId: string) => Project = (
  state,
  projectId
) => fromProjects.getProject(state.projects, projectId);
export const getProjectByIdState: (
  state: any
) => fromProjects.ProjectByIdState = (state) =>
  fromProjects.getProjectByIdState(state.projects);
export const getProjectRedirect: (state: any, temporaryId: string) => string = (
  state,
  temporaryId
) => fromProjects.getRedirect(state.projects, temporaryId);
export const getProjectsIsFetching: (state: any) => boolean = (state) =>
  fromProjects.getIsFetching(state.projects);
export const getProjectsHasError: (state: any) => boolean = (state) =>
  fromProjects.getHasError(state.projects);
export const getProjectsErrorMessage: (state: any) => string = (state) =>
  fromProjects.getErrorMessage(state.projects);

export const getInternalProjectContactsData: (
  state: any
) => fromInternalProjectContacts.DataState = (state: any) =>
  fromInternalProjectContacts.getData(state.internalProjectContacts);
export const getAllInternalProjectContacts: (
  state: any,
  projectId: ProjectId
) => InternalProjectContact[] = (state: any, projectId: ProjectId) =>
  fromInternalProjectContacts.getAllInternalProjectContacts(
    state.internalProjectContacts,
    projectId
  );
export const getInternalProjectContactsIsFetching: (state: any) => boolean = (
  state
) => fromInternalProjectContacts.getIsFetching(state.internalProjectContacts);
export const getInternalProjectContactsHasError: (state: any) => boolean = (
  state
) => fromInternalProjectContacts.getHasError(state.internalProjectContacts);
export const getInternalProjectContactsErrorMessage: (state: any) => string = (
  state
) => fromInternalProjectContacts.getErrorMessage(state.internalProjectContacts);
export const isInternalProjectMember: (
  state: any,
  projectId: ProjectId,
  contactId: ContactId
) => boolean = (state, projectId, contactId) =>
  fromInternalProjectContacts.isProjectMember(
    state.internalProjectContacts,
    projectId,
    contactId
  );

export const getInternalProjectRoles: (
  state: any,
  projectId: ProjectId,
  contactId: ContactId
) => ProjectRole[] = (state, projectId, contactId) =>
  fromInternalProjectContacts.getInternalProjectRoles(
    state.internalProjectContacts,
    projectId,
    contactId
  );

export const getExternalProjectContactsData: (
  state: any
) => fromExternalProjectContacts.DataState = (state: any) =>
  fromExternalProjectContacts.getData(state.externalProjectContacts);
export const getAllExternalProjectContacts: (
  state: any,
  projectId: ProjectId
) => ExternalProjectContact[] = (state: any, projectId: ProjectId) =>
  fromExternalProjectContacts.getAllExternalProjectContacts(
    state.externalProjectContacts,
    projectId
  );
export const getExternalProjectContactsIsFetching: (state: any) => boolean = (
  state
) => fromExternalProjectContacts.getIsFetching(state.externalProjectContacts);
export const getExternalProjectContactsHasError: (state: any) => boolean = (
  state
) => fromExternalProjectContacts.getHasError(state.externalProjectContacts);
export const getExternalProjectContactsErrorMessage: (state: any) => string = (
  state
) => fromExternalProjectContacts.getErrorMessage(state.externalProjectContacts);

export const getHourlyRatesData: (state: any) => fromHourlyRates.DataState = (
  state: any
) => fromHourlyRates.getData(state.hourlyRates);
export const getAllHourlyRates: (
  state: any,
  projectId: ProjectId
) => HourlyRate[] = (state: any, projectId: ProjectId) =>
  fromHourlyRates.getAllHourlyRates(state.hourlyRates, projectId);
export const getHourlyRatesIsFetching: (state: any) => boolean = (state) =>
  fromHourlyRates.getIsFetching(state.hourlyRates);
export const getHourlyRatesHasError: (state: any) => boolean = (state) =>
  fromHourlyRates.getHasError(state.hourlyRates);
export const getHourlyRatesErrorMessage: (state: any) => string = (state) =>
  fromHourlyRates.getErrorMessage(state.hourlyRates);

export const getKilometerRateData: (
  state: any
) => fromKilometerRates.DataState = (state: any) =>
  fromKilometerRates.getData(state.kilometerRates);
export const getKilometerRate: (
  state: any,
  projectId: ProjectId
) => KilometerRate = (state: any, projectId: ProjectId) =>
  fromKilometerRates.getKilometerRate(state.kilometerRates, projectId);
export const getKilometerRateIsFetching: (state: any) => boolean = (state) =>
  fromKilometerRates.getIsFetching(state.kilometerRates);
export const getKilometerRateHasError: (state: any) => boolean = (state) =>
  fromKilometerRates.getHasError(state.kilometerRates);
export const getKilometerRateErrorMessage: (state: any) => string = (state) =>
  fromKilometerRates.getErrorMessage(state.kilometerRates);

export const getActiveProject: (state: any) => string = (state) =>
  fromActiveProject.getActiveProject(state.activeProject);

export const getActiveProjectProject: (state: any) => Project = (state) =>
  fromProjects.getProject(
    state.projects,
    fromActiveProject.getActiveProject(state.activeProject)
  );

export const getActiveProjectTabView: (state: any) => string = (state) =>
  fromActiveProjectTabView.getActiveProjectTabView(state.activeProjectTabView);

export const getProjectsOverview: (state: any) => Array<Project> = (state) =>
  fromProjects.getProjectsOverview(state.projects);

export const getLastProjectLocation: (state: any) => string = (state) =>
  fromLastProjectLocation.getLastProjectLocation(state.lastProjectLocation);

export const getProjectDistributionListData: (
  state: any
) => fromHourlyRates.DataState = (state: ProjectsReducerState) =>
  fromHourlyRates.getData(state.projectDistributionList);
export const getProjectDistributionLists: (
  state: ProjectsReducerState,
  projectId: ProjectId
) => ProjectDistributionList[] = (state, projectId: ProjectId) =>
  fromProjectDistributionList.getProjectDistributionLists(
    state.projectDistributionList,
    projectId
  );
export const getProjectDistributionListIsFetching: (
  state: ProjectsReducerState
) => boolean = (state) =>
  fromProjectDistributionList.getIsFetching(state.projectDistributionList);
export const getProjectDistributionListHasError: (
  state: ProjectsReducerState
) => boolean = (state) =>
  fromProjectDistributionList.getHasError(state.projectDistributionList);
export const getProjectDistributionListErrorMessage: (
  state: ProjectsReducerState
) => string = (state) =>
  fromProjectDistributionList.getErrorMessage(state.projectDistributionList);

export const getProjectDistributionListDrawerState: (
  state: ProjectsReducerState
) => fromProjectDistributionList.ProjectDistributionListDrawerState = (state) =>
  fromProjectDistributionList.getDrawerState(state.projectDistributionList);

export const getMyProjectIds: (state: ProjectsReducerState) => ProjectId[] = (
  state
) => fromProjects.getMyProjectIds(state.projects);

export const getAllProjectIds: (state: ProjectsReducerState) => ProjectId[] = (
  state
) => fromProjects.getAllProjectIds(state.projects);

export const getProjectNewsByProjectId: (
  state: ProjectsReducerState,
  projectId: string
) => ProjectNewsCategoryKey = (state, projectId) =>
  fromProjectNews.getProjectNewsByProjectId(state.projectNews, projectId);

export const getProjectNews: (
  state: ProjectsReducerState,
  projectIds?: string[]
) => ProjectNews = (state, projectIds) =>
  fromProjectNews.getProjectNews(state.projectNews, projectIds);

export const getProjectNewsCounts: (
  state: ProjectsReducerState,
  projectIds?: string[]
) => ProjectsWithNewsCounts = (state, projectIds) => {
  return fromProjectNewsCounts.projectNewsCounts(
    state.projectNewsCounts,
    projectIds
  );
};

export const getProjectNewsCountsByProjectId: (
  state: ProjectsReducerState,
  projectId: string
) => ProjectNewsCounts = (state, projectId) => {
  return fromProjectNewsCounts.projectNewsCountsByProjectId(
    state.projectNewsCounts,
    projectId
  ); // WIP
};
