import { put, select } from 'redux-saga/effects';
import { CopyMessageWs } from '../../../models/Message';
import { notification } from 'antd';
import i18n from '../../../i18n';
import DuplicateMessageNotification from '../../documents/components/Notifications/DuplicateMessageNotification';
import { getMessage, getUserMeContactId } from '../../../apps/main/rootReducer';

export declare type WSTypesEmailCopyEvent =
  | 'copySuccess'
  | 'copyFailure'
  | 'copyDuplicate';

export function* handleEmailCopyProgressMessage(data: any) {
  try {
    const currentUserId = yield select(getUserMeContactId);
    const objectType: CopyMessageWs = data.objectType;
    const sourceProjectId =
      objectType?.sourceProjectId === currentUserId
        ? 'me'
        : objectType?.sourceProjectId;
    const sourceMessageId = objectType?.copyMessageDto?.messageId;
    const destinationMailFolder = objectType?.targetFolderId;
    const destinationProjectId = data.object;
    const sourceMessage = yield select((state) =>
      getMessage(state, sourceProjectId, sourceMessageId)
    );

    yield put({
      type: 'COPY_MAIL_ASYNC',
      meta: {
        messageId: sourceMessageId,
      },
    });

    if (data.type === 'copyDuplicate' && !objectType.hideDuplicateMessages) {
      notification.open({
        message: i18n.t('mail:notification.duplicateMessageFound'),
        description: (
          <DuplicateMessageNotification
            sourceMessage={sourceMessage}
            sourceProjectId={sourceProjectId}
            copyMessageWs={objectType}
            destinationProjectId={destinationProjectId}
            destinationMailFolder={destinationMailFolder}
          />
        ),
        key: `duplicateMessage-${sourceMessageId}`,
        duration: 30,
      });
    }
  } catch (innerError) {
    console.error('Error handling EmailCopyProgressMessage:', innerError, data);
  }
}
