import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Menu } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/pro-light-svg-icons';
import classNames from 'classnames';
import { useTheme } from 'react-jss';
import { makePrioStyles } from '../../../../../../theme/utils';
import { PrioTheme } from '../../../../../../theme/types';
import Flex from '../../../../../../components/Flex';
import { ProjectId } from '../../../../../../models/Types';
import { ActionBar } from '@prio365/prio365-react-library';
import { apiGetPromanReportCollections } from '../../../../api/proman';
import {
  ReportCollection,
  Report,
} from '../../../../../../models/ProjectExtension/Proman';
import { fullDateFormatFormatString } from '../../../../../../util';
import { Project } from '../../../../../../models/Project';
import { ProjectExtensionType } from '../../../../../../models/ProjectExtension';
import { useSelector } from 'react-redux';
import {
  getProject,
  RootReducerState,
} from '../../../../../../apps/main/rootReducer';

const useStyles = makePrioStyles((theme) => ({
  root: { height: '100%' },
  menu: {
    flex: 1,
    maxWidth: '280px',
    width: '280px',
    overflowY: 'auto',
    backgroundColor: theme.old.palette.backgroundPalette.sub,
  },
  rowContent: {
    overflow: 'hidden',
    height: '100%',
    backgroundColor: theme.old.palette.backgroundPalette.content,
  },
  content: {
    flex: 1,
    padding: theme.old.spacing.defaultPadding,
    width: '100%',
  },
  title: {
    lineHeight: '44px!important',
    padding: `${theme.old.spacing.unit(2)}px ${
      theme.old.spacing.defaultPadding
    }px`,
    margin: '0!important',
  },
  protocolDate: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.old.spacing.unit(1),
  },
  protocolIcon: {
    fontSize: 16,
  },
  actionBar: {
    marginBottom: theme.old.spacing.unit(2),
  },
  protocolView: {
    height: '95%',
    width: '100%',
    margin: '0 auto',
    backgroundColor: theme.old.palette.backgroundPalette.sub,
    display: 'flex', // Enables Flexbox
    justifyContent: 'center', // Centers horizontally
  },
}));

const handleDownload = (downloadUrl: string) => {
  const link = document.createElement('a');
  link.href = downloadUrl;
  link.download = downloadUrl.split('/').pop() || 'download';
  link.click();
};

const handleOpenInNewTab = (url: string) => {
  window.open(url, '_blank');
};

const getProjectExtensionMe = (
  project: Project,
  type: ProjectExtensionType
) => {
  if (!project) {
    return undefined;
  }
  return project.projectExtensionMeDtos?.find(
    (ext) => ext.projectExtensionType === type
  );
};

interface PromanPageProps {
  projectId: ProjectId;
  className?: string;
}

const PromanPage: React.FC<PromanPageProps> = (props) => {
  const classes = useStyles();
  const { className, projectId } = props;
  const { t } = useTranslation();
  const theme = useTheme<PrioTheme>();
  const [selectedProtocol, setSelectedProtocol] = useState<Report | null>(null);
  const [reportCollections, setReportCollections] = useState<
    ReportCollection[]
  >([]);
  const project = useSelector<RootReducerState, Project>((state) =>
    getProject(state, projectId)
  );
  const promanProjectExtension = useMemo(() => {
    return getProjectExtensionMe(project, 'proman');
  }, [project]);

  const mandant = promanProjectExtension?.value;
  const promanProjectId = promanProjectExtension?.key;

  useEffect(() => {
    const fetchReportCollections = async () => {
      const result = await apiGetPromanReportCollections(123);
      if (result.data) {
        setReportCollections(result.data.ReportCollections);
      }
    };

    fetchReportCollections();
  }, [projectId]);

  const handleProtocolClick = (protocol: Report) => {
    setSelectedProtocol(protocol);
  };

  const protocols = reportCollections.flatMap(
    (collection) => collection.Reports
  );

  return (
    <Flex.Column className={classNames(classes.root, className)}>
      <Flex.Row className={classes.rowContent}>
        {/* Sidebar Menu */}
        <Flex.Column
          flexGrow={1}
          className={classNames('prio-module-navigation', classes.menu)}
        >
          <Typography.Title className={classes.title}>
            {t('settings:genericSettingsMenu.protocolsTitle', 'Protokolle')}
          </Typography.Title>

          <Menu
            mode="inline"
            selectedKeys={[selectedProtocol?.ReportId.toString()]}
          >
            {reportCollections.map((collection) => (
              <Menu.SubMenu
                key={collection.ReportCollectionId}
                style={{
                  backgroundColor: theme.old.palette.backgroundPalette.sub,
                }}
                title={collection.GlobalReportTitle}
              >
                {protocols
                  .filter(
                    (protocol) =>
                      protocol.ReportCollectionID ===
                      collection.ReportCollectionId
                  )
                  .map((protocol) => (
                    <Menu.Item key={protocol.ReportId}>
                      <div
                        className={classes.protocolDate}
                        onClick={() => handleProtocolClick(protocol)}
                      >
                        <FontAwesomeIcon
                          icon={faFileAlt}
                          className={classes.protocolIcon}
                        />
                        {fullDateFormatFormatString(protocol.MeetingDate)}
                      </div>
                    </Menu.Item>
                  ))}
              </Menu.SubMenu>
            ))}
          </Menu>
        </Flex.Column>

        {/* Fullscreen Content */}
        <div className={classes.content}>
          {selectedProtocol ? (
            <>
              <div className={classes.actionBar}>
                <ActionBar
                  buttons={[
                    {
                      id: 'downloadButton',
                      size: 'default',
                      shape: 'default',
                      disabled: !selectedProtocol?.Documents[0],
                      type: 'primary',
                      children: t(`projects:addIn.extensions.proman.download`),
                      onClick: () =>
                        handleDownload(
                          selectedProtocol?.Documents[0]?.DownloadUrl
                        ),
                    },
                    {
                      id: 'openInNewTabButton',
                      size: 'default',
                      shape: 'default',
                      type: 'default',
                      children: t(
                        `projects:addIn.extensions.proman.openInNewTab`
                      ),
                      onClick: () =>
                        handleOpenInNewTab(
                          `https://portal.proman.at/meetings/reportdrawing/${mandant}/${promanProjectId}/${promanProjectId}/${selectedProtocol?.ReportCollectionID}/${selectedProtocol?.ReportId}`
                        ),
                    },
                  ]}
                ></ActionBar>
              </div>
              <div className={classes.protocolView}>
                {selectedProtocol?.Documents[0] ? (
                  <object
                    data={selectedProtocol?.Documents[0]?.DownloadUrl}
                    type="application/pdf"
                    width="100%"
                    height="100%"
                  >
                    <p>
                      {t('projects:addIn.extensions.proman.pdfNotSupported')}{' '}
                      <a href={selectedProtocol?.Documents[0]?.DownloadUrl}>
                        {t('projects:addIn.extensions.proman.downloadHere')}
                      </a>
                      .
                    </p>
                  </object>
                ) : (
                  <>
                    <p>{t('projects:addIn.extensions.proman.NoPdf')}</p>
                  </>
                )}
              </div>
            </>
          ) : (
            <div>{t('common:pleaseSelect')} - Protokolle</div>
          )}
        </div>
      </Flex.Row>
    </Flex.Column>
  );
};

export default PromanPage;
