import { combineReducers, Reducer } from 'redux';
import {
  DELETE_ALL_PROJECT_NEWS_COMMIT,
  DELETE_PROJECT_NEWS_BY_PROJECT_ID_AND_TYPE_COMMIT,
  DELETE_PROJECT_NEWS_BY_PROJECT_ID_COMMIT,
  FETCH_PROJECT_NEWS_COUNTS_COMMIT,
  FETCH_PROJECT_NEWS_COUNTS_REQUEST,
  FETCH_PROJECT_NEWS_COUNTS_ROLLBACK,
  SUBTRACT_1_PROJECT_NEWS_COUNTS_BY_PROJECT_ID_AND_REFERENCE_OBJECT_ID,
} from '../actions';
import { CLEAR_PRIO_CACHE } from '../../../actions';
import {
  ProjectNews,
  ProjectNewsCategoryKey,
  ProjectNewsCounts,
  ProjectNewsDto,
  ProjectsWithNewsCounts,
} from '../../../models/Project';
import { isObjEmpty } from '../../../util';

/**
 * Function to get all types of projectNews which have a projectNewsDto with a given referenceObjectId
 * @param projectNews A projectNews Object
 * @param projectId A projectId of the project
 * @param referenceObjectId An Id of a reference object, e.g. a messageId
 * @returns Array of projectNewsTypes, e.g. all projectNewsTypes in which there is a projectNewsDto with the given messageId (e.g. ['messageAssignmentUnread'])
 */
const getProjectNewsTypesWithReferenceObjectId = (
  projectNews: ProjectNews,
  projectId: string,
  referenceObjectId: string
): string[] => {
  const projectNewsOfProjectId = projectNews[
    projectId
  ] as ProjectNewsCategoryKey;
  const allProjectNewsByProjectId = Object.values(
    projectNewsOfProjectId || {}
  ).flat();
  const typesWithReferenceObjectId: string[] = (
    Object.values(allProjectNewsByProjectId || []) as ProjectNewsDto[]
  )
    .filter(
      (projectNews) => projectNews.referenceObjectId === referenceObjectId
    )
    .map((projectNews) => projectNews.projectNewsType);
  return typesWithReferenceObjectId;
};

export interface ProjectNewsCountState {
  byId: ProjectsWithNewsCounts;
  meta: ProjectNewsMeta;
}

export interface ProjectNewsByIdState {
  [projectId: string]: ProjectNewsCounts;
}

const byId: Reducer<ProjectsWithNewsCounts, any> = (state = {}, action) => {
  switch (action.type) {
    case FETCH_PROJECT_NEWS_COUNTS_COMMIT: {
      const { payload } = action;
      return Object.entries(payload).reduce((acc, [projectId, news]) => {
        acc[projectId] = news;
        return acc;
      }, {});
    }

    case DELETE_PROJECT_NEWS_BY_PROJECT_ID_COMMIT: {
      const {
        meta: { projectId },
      } = action;
      const { [projectId]: news, ...rest } = state;
      if (news) {
        return rest;
      }
      return state;
    }

    case DELETE_PROJECT_NEWS_BY_PROJECT_ID_AND_TYPE_COMMIT: {
      const {
        meta: { projectId, types },
      } = action;
      const { [projectId]: news, ...rest } = state;
      types.forEach((type) => {
        if (news[type]) {
          delete news[type];
        }
      });

      if (isObjEmpty(news) && !isObjEmpty(rest)) {
        return rest;
      }
      if (isObjEmpty(news) && isObjEmpty(rest)) {
        return {};
      }
      return { [projectId]: news, ...rest };
    }

    case SUBTRACT_1_PROJECT_NEWS_COUNTS_BY_PROJECT_ID_AND_REFERENCE_OBJECT_ID: {
      const { projectId, referenceObjectId, projectNews } = action;
      const projectNewsCountsByProjectId = state[projectId];
      if (!projectNewsCountsByProjectId) return state;

      const projectNewsTypesWithReferenceObjectId =
        getProjectNewsTypesWithReferenceObjectId(
          projectNews,
          projectId,
          referenceObjectId
        );

      // Update project news counts for given projectNewsTypes
      const _projectNewsCounts: ProjectNewsCounts = Object.entries(
        projectNewsCountsByProjectId || {}
      ).reduce((acc, [type, count]) => {
        const subtractCounts = projectNewsTypesWithReferenceObjectId.includes(
          type
        )
          ? 1
          : 0;

        if (count - subtractCounts < 1) {
          return { ...acc };
        }
        return { ...acc, [type]: count - subtractCounts };
      }, {});

      return {
        ...state,
        [projectId]: _projectNewsCounts,
      };
    }

    case DELETE_ALL_PROJECT_NEWS_COMMIT:
    case CLEAR_PRIO_CACHE: {
      return {};
    }
    default:
      return state;
  }
};

interface ProjectNewsMeta {
  isFetching: boolean;
  hasError: boolean;
  errorMessage?: string;
}

const meta: Reducer<ProjectNewsMeta, any> = (
  state = { isFetching: false, hasError: false },
  action
) => {
  switch (action.type) {
    case FETCH_PROJECT_NEWS_COUNTS_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case FETCH_PROJECT_NEWS_COUNTS_COMMIT: {
      return {
        ...state,
        isFetching: false,
      };
    }
    case FETCH_PROJECT_NEWS_COUNTS_ROLLBACK: {
      return {
        ...state,
        isFetching: false,
        hasError: true,
        errorMessage: 'projects:errorMessages.fetchProjectNews',
      };
    }
    case CLEAR_PRIO_CACHE: {
      return { isFetching: false, hasError: false };
    }
    default:
      return state;
  }
};

export default combineReducers<ProjectNewsCountState>({
  byId,
  meta,
});

export const projectNewsCounts: (
  state: ProjectNewsCountState,
  projectIds?: string[]
) => ProjectsWithNewsCounts = (state, projectIds) =>
  filterProjectNewsCountsByIds(state?.byId, projectIds);

function filterProjectNewsCountsByIds(
  state: ProjectsWithNewsCounts,
  projectIds?: string[]
) {
  if (!projectIds) return state;
  let newCounts = {};
  const _projectIds = projectIds?.map((id) => id.toUpperCase()) || [];
  for (let key in state) {
    if (_projectIds.includes(key?.toUpperCase())) newCounts[key] = state[key];
  }
  return newCounts;
}

export const projectNewsCountsByProjectId: (
  state: ProjectNewsCountState,
  projectId: string
) => ProjectNewsCounts = (state, projectId) =>
  state?.byId?.[projectId.toUpperCase()] ||
  state?.byId?.[projectId.toLowerCase()] ||
  state?.byId?.[projectId];

export const getIsFetching: (state: ProjectNewsCountState) => boolean = (
  state
) => state.meta.isFetching;
export const getHasError: (state: ProjectNewsCountState) => boolean = (state) =>
  state.meta.hasError;
export const getErrorMessage: (state: ProjectNewsCountState) => string = (
  state
) => state.meta.errorMessage;
