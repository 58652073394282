import { PrioTheme } from '../../../theme/types';
import { makePrioStyles } from '../../../theme/utils';
import classNames from 'classnames';
import CustomTag, { CustomTagOption } from '../../CustomTag';
import React from 'react';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {},
  tag: {},
  dropdownTagContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '5px',
  },
  dropdownChevron: {
    transition: '0.1s',
  },
  quickFilterDropDown: {
    '& .ant-dropdown-menu-root': {
      marginRight: '-8px',
    },
  },
}));

export interface QuickSearchOption {
  name: string;
  value: any;
}

export declare type QuickSearchConfig = {
  [key: string]: {
    value?: CustomTagOption;
    defaultValue?: CustomTagOption;
    options?: CustomTagOption[];
    onClick: (
      value: any,
      e: React.MouseEvent<HTMLSpanElement, MouseEvent>
    ) => void;
    nonVisible?: boolean;
    onValueChange?: (value: any) => void;
  };
};

interface UltimateFilterQuickSearchTagsProps {
  className?: string;
  config: QuickSearchConfig;
}

export const UltimateFilterQuickSearchTags = (
  props: UltimateFilterQuickSearchTagsProps,
  className
) => {
  const { config } = props;
  const classes = useStyles();

  const renderTags = () => {
    return Object.keys(config).map((key) => {
      const { onClick, value, options, defaultValue, nonVisible } = config[key];

      const onSubmit = (
        value: any,
        e: React.MouseEvent<HTMLSpanElement, MouseEvent>
      ) => {
        onClick?.(value, e);
      };

      if (options) {
        return (
          <CustomTag
            type="options"
            key={key}
            classicStyle
            onClick={onSubmit}
            defaultValue={defaultValue}
            options={options}
            onValueChange={(value) => {
              config[key].onValueChange?.(value);
            }}
          />
        );
      }
      if (nonVisible) {
        return null;
      }
      return (
        <CustomTag
          type="text"
          value={value}
          key={key}
          classicStyle
          onClick={onSubmit}
        />
      );
    });
  };
  return (
    <div className={classNames(classes.root, className)}>{renderTags()}</div>
  );
};
