import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import {} from 'antd';
import { makePrioStyles } from '../../../../theme/utils';
import {
  MonthlyClose,
  MonthlyCloseCalculatedData,
} from '../../../../models/TimeKeeping';
import { GenericSearchResultItem } from '../../../../components/Filter/types';
import { apiFetchSearchData } from '../../../../components/Filter/api';
import { HolidayEntitlement } from '../../../../models/Employee';
import { AbsenceProposal } from '../../../../models/AbsenceProposal';
import useContactsContext from '../../../contacts/hooks/useContactsProvider';
import { Column } from '@prio365/prio365-react-library/lib/VirtualTable/components/VirtualTable';
import FilterContextVirtualTable from '../../../../components/Filter/FilterContextVirtualTable';
import FilterResultNoItemsScreen from '../../../../components/Filter/FilterResultNoItemsScreen';

const useStyles = makePrioStyles((theme) => ({
  root: {
    margin: theme.spacing.regular,
    '&.prio-virtual-table': {
      height: 'unset',
    },
  },
  cell: {
    display: 'flex',
    alignItems: 'center',
  },
  row: {
    //cursor: 'pointer'
  },
  cellCenter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  lastCell: {
    padding: '10px 0px 10px 2px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
}));

interface HRTimeKeepingOverviewPageProps {
  className?: string;
  officeId: string;
}

export const HRTimeKeepingOverviewPage: React.FC<
  HRTimeKeepingOverviewPageProps
> = (props) => {
  //#region ------------------------------ Defaults
  const { className, officeId } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const { getContactById } = useContactsContext();
  const [tableData, setTableData] = useState<any[]>([]);
  //#endregion

  //#region ------------------------------ Methods / Handlers

  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    const fetchAndProcessData = async (officeId: string) => {
      const currentYear = new Date().getFullYear();
      const nextYear = currentYear + 1;

      const [monthlyCloses, holidayEntitlements, absenceProposals] =
        await Promise.all([
          apiFetchSearchData<MonthlyClose, MonthlyCloseCalculatedData>(
            'monthlyCloses',
            `Calculated.OfficeId eq '${officeId}' & Data.Month ge '${currentYear}-01-01T00:00:00.000+00:00' & Data.Month le '${currentYear}-12-31T23:59:59.999+00:00'`,
            2000,
            true
          ),
          apiFetchSearchData<HolidayEntitlement, HolidayEntitlement>(
            'holidayEntitlements',
            `Calculated.OfficeId eq '${officeId}'`,
            2000,
            true
          ),
          apiFetchSearchData<AbsenceProposal, AbsenceProposal>(
            'absenceProposals',
            `Data.OfficeId eq '${officeId}' & Data.AbsenceState eq 'accepted' & Transformed.Timespan ge '${nextYear}-01-01T00:00:00.000' & Transformed.Timespan le '${nextYear}-12-31T23:59:59.999'`,
            2000,
            true
          ),
        ]);

      const results = combineResults(
        monthlyCloses.data.items,
        holidayEntitlements.data.items,
        absenceProposals.data.items
      );
      setTableData(results);
    };
    const combineResults = (
      monthlyCloses: GenericSearchResultItem<
        MonthlyClose,
        MonthlyCloseCalculatedData
      >[],
      holidayEntitlements: GenericSearchResultItem<
        HolidayEntitlement,
        HolidayEntitlement
      >[],
      absenceProposals: GenericSearchResultItem<
        AbsenceProposal,
        AbsenceProposal
      >[]
    ) => {
      const employeeMap: Record<string, any> = {};

      // Process Monthly Closes
      monthlyCloses.forEach((close) => {
        const employeeId = close.data.employeeId;

        if (!employeeMap[employeeId]) {
          const contact = getContactById(employeeId); // Retrieve contact details
          employeeMap[employeeId] = {
            firstName: contact?.firstName || '',
            lastName: contact?.lastName || '',
            holidayEntitlements: 0,
            remainingLeave: 0,
            remainingLeaveLastYear: 0,
            paidOvertime: 0,
            currentOvertime: 0,
            sickDaysCurrentYear: 0,
            overtimeCompensationCurrentYear: 0,
            approvedLeaveNextYear: 0,
            approvedOvertimeCompensationNextYear: 0,
          };
        }

        const employee = employeeMap[employeeId];

        var yearAndMonth = new Date(close.data.month).toISOString().slice(0, 7);
        if (yearAndMonth === new Date().toISOString().slice(0, 7)) {
          console.log('set it', close.data);
          employee.currentOvertime = close.data.accumulatedOvertimeHours || 0;
        }

        employee.paidOvertime += close.data.compensationPaymentHours || 0;
        employee.sickDaysCurrentYear +=
          close.data.monthlyCloseAbsences
            ?.filter((absence) => absence.type === 'dayOfIllness')
            .reduce((sum, absence) => sum + (absence.absentWorkDays || 0), 0) ||
          0;
        employee.overtimeCompensationCurrentYear +=
          close.data.monthlyCloseAbsences
            ?.filter((absence) => absence.type === 'overtimeCompensation')
            .reduce((sum, absence) => sum + (absence.absentWorkDays || 0), 0) ||
          0;
      });

      // Process Holiday Entitlements
      holidayEntitlements.forEach((entitlement) => {
        const employeeId = entitlement.data.employeeId;

        if (!employeeMap[employeeId]) {
          const contact = getContactById(employeeId); // Retrieve contact details
          employeeMap[employeeId] = {
            firstName: contact?.firstName || '',
            lastName: contact?.lastName || '',
            holidayEntitlements: 0,
            remainingLeave: 0,
            remainingLeaveLastYear: 0,
            paidOvertime: 0,
            currentOvertime: 0,
            sickDaysCurrentYear: 0,
            overtimeCompensationCurrentYear: 0,
            approvedLeaveNextYear: 0,
            approvedOvertimeCompensationNextYear: 0,
          };
        }

        const employee = employeeMap[employeeId];

        if (entitlement.data.year === new Date().getFullYear()) {
          employee.holidayEntitlements =
            entitlement.data?.holidayEntitlement || 0;
          employee.remainingLeave = entitlement.data?.remainingEntitlement || 0;
        }
        if (entitlement.data.year === new Date().getFullYear() - 1) {
          employee.remainingLeaveLastYear +=
            entitlement.data?.remainingEntitlement || 0;
        }
      });

      // Process Absence Proposals
      absenceProposals.forEach((proposal) => {
        const employeeId = proposal.data.applicantId;

        if (!employeeMap[employeeId]) {
          const contact = getContactById(employeeId); // Retrieve contact details
          employeeMap[employeeId] = {
            firstName: contact?.firstName || '',
            lastName: contact?.lastName || '',
            holidayEntitlements: 0,
            remainingLeave: 0,
            remainingLeaveLastYear: 0,
            paidOvertime: 0,
            currentOvertime: 0,
            sickDaysCurrentYear: 0,
            overtimeCompensationCurrentYear: 0,
            approvedLeaveNextYear: 0,
            approvedOvertimeCompensationNextYear: 0,
          };
        }

        const employee = employeeMap[employeeId];

        // Check absence type and aggregate accordingly
        if (
          proposal.data.absenceType === 'annualLeave' &&
          proposal.data.from.startsWith('2025')
        ) {
          employee.approvedLeaveNextYear += proposal.data.absentWorkdays || 0;
        }

        if (
          proposal.data.absenceType === 'overtimeCompensation' &&
          proposal.data.from.startsWith('2025')
        ) {
          // calculate the time range in days from to and add days
          var days = Math.floor(
            (new Date(proposal.data.to).getTime() -
              new Date(proposal.data.from).getTime()) /
              (1000 * 60 * 60 * 24)
          );

          employee.approvedOvertimeCompensationNextYear += days || 0;
        }
      });

      // Convert map to array
      return Object.values(employeeMap);
    };
    if (officeId) {
      fetchAndProcessData(officeId);
    }
  }, [officeId, getContactById]);
  //#endregion

  //#region ------------------------------ Table Columns
  const columns: Column<any>[] = [
    {
      id: 'employee',
      width: 200,
      title: t('hr:timeAndLeaveManagement.overview.employee'),
      accessor: 'firstName',
      sortingFn: (rowA, rowB) => {
        const contactA = `${rowA.firstName} ${rowA.lastName}`;
        const contactB = `${rowB.firstName} ${rowB.lastName}`;
        return contactA.localeCompare(contactB);
      },
      Cell: ({ originalData }) => {
        return (
          <div>
            {originalData.firstName} {originalData.lastName}
          </div>
        );
      },
      className: classes.cell,
      alignSelf: true,
    },
    {
      id: 'holidayEntitlements',
      width: 150,
      title: t('hr:timeAndLeaveManagement.overview.holidayEntitlements'),
      accessor: 'holidayEntitlements',
      sortingFn: (rowA, rowB) =>
        rowA.holidayEntitlements - rowB.holidayEntitlements,
      Cell: ({ originalData }) => (
        <div>{originalData.holidayEntitlements || '-'}</div>
      ),
      className: classes.cell,
      alignSelf: true,
    },
    {
      id: 'remainingLeave',
      width: 150,
      title: t('hr:timeAndLeaveManagement.overview.remainingLeave'),
      accessor: 'remainingLeave',
      sortingFn: (rowA, rowB) => rowA.remainingLeave - rowB.remainingLeave,
      Cell: ({ originalData }) => (
        <div>{originalData.remainingLeave || '-'}</div>
      ),
      className: classes.cell,
      alignSelf: true,
    },
    {
      id: 'remainingLeaveLastYear',
      width: 150,
      title: t('hr:timeAndLeaveManagement.overview.remainingLeaveLastYear', {
        lastYear: new Date().getFullYear() - 1,
      }),
      accessor: 'remainingLeaveLastYear',
      sortingFn: (rowA, rowB) =>
        rowA.remainingLeaveLastYear - rowB.remainingLeaveLastYear,
      Cell: ({ originalData }) => (
        <div>{originalData.remainingLeaveLastYear || '-'}</div>
      ),
      className: classes.cell,
      alignSelf: true,
    },
    {
      id: 'paidOvertime',
      width: 150,
      title: t('hr:timeAndLeaveManagement.overview.paidOvertime'),
      accessor: 'paidOvertime',
      sortingFn: (rowA, rowB) => rowA.paidOvertime - rowB.paidOvertime,
      Cell: ({ originalData }) => <div>{originalData.paidOvertime || '-'}</div>,
      className: classes.cell,
      alignSelf: true,
    },
    {
      id: 'currentOvertime',
      width: 150,
      title: t('hr:timeAndLeaveManagement.overview.currentOvertime'),
      accessor: 'currentOvertime',
      sortingFn: (rowA, rowB) => rowA.currentOvertime - rowB.currentOvertime,
      Cell: ({ originalData }) => (
        // parse to two decimal places but with , and only if there are some decimal
        <div>
          {originalData.currentOvertime.toString().includes('.')
            ? originalData.currentOvertime.toFixed(2).replace('.', ',')
            : originalData.currentOvertime || '-' || '-'}
        </div>
      ),
      className: classes.cell,
      alignSelf: true,
    },
    {
      id: 'sickDaysCurrentYear',
      width: 150,
      title: t('hr:timeAndLeaveManagement.overview.sickDaysCurrentYear', {
        currentYear: new Date().getFullYear(),
      }),
      accessor: 'sickDaysCurrentYear',
      sortingFn: (rowA, rowB) =>
        rowA.sickDaysCurrentYear - rowB.sickDaysCurrentYear,
      Cell: ({ originalData }) => (
        <div>{originalData.sickDaysCurrentYear || '-'}</div>
      ),
      className: classes.cell,
      alignSelf: true,
    },
    {
      id: 'overtimeCompensationCurrentYear',
      width: 150,
      title: t(
        'hr:timeAndLeaveManagement.overview.overtimeCompensationCurrentYear',
        {
          currentYear: new Date().getFullYear(),
        }
      ),
      accessor: 'overtimeCompensationCurrentYear',
      sortingFn: (rowA, rowB) =>
        rowA.overtimeCompensationCurrentYear -
        rowB.overtimeCompensationCurrentYear,
      Cell: ({ originalData }) => (
        <div>{originalData.overtimeCompensationCurrentYear || '-'}</div>
      ),
      className: classes.cell,
      alignSelf: true,
    },
    {
      id: 'approvedLeaveNextYear',
      width: 150,
      title: t('hr:timeAndLeaveManagement.overview.approvedLeaveNextYear', {
        nextYear: new Date().getFullYear() + 1,
      }),
      accessor: 'approvedLeaveNextYear',
      sortingFn: (rowA, rowB) =>
        rowA.approvedLeaveNextYear - rowB.approvedLeaveNextYear,
      Cell: ({ originalData }) => (
        <div>{originalData.approvedLeaveNextYear || '-'}</div>
      ),
      className: classes.cell,
      alignSelf: true,
    },
    {
      id: 'approvedOvertimeCompensationNextYear',
      width: 150,
      title: t(
        'hr:timeAndLeaveManagement.overview.approvedOvertimeCompensationNextYear',
        {
          nextYear: new Date().getFullYear() + 1,
        }
      ),
      accessor: 'approvedOvertimeCompensationNextYear',
      sortingFn: (rowA, rowB) =>
        rowA.approvedOvertimeCompensationNextYear -
        rowB.approvedOvertimeCompensationNextYear,
      Cell: ({ originalData }) => (
        <div>{originalData.approvedOvertimeCompensationNextYear || '-'}</div>
      ),
      className: classes.cell,
      alignSelf: true,
    },
  ];
  //#endregion

  return (
    <FilterContextVirtualTable<any>
      id={'timekeepingOverview'}
      className={classNames(classes.root, className)}
      columns={columns}
      data={tableData}
      //selectedItems={selectedSearchItems}
      resizable="absolute"
      //onRow={handleOnRow}
      classNameTableRow={classes.row}
      // onSelectionChange={onSelectionChange}
      onCheckEquality={(a, b) =>
        a.firstName === b.firstName && a.lastName === b.lastName
      }
      noItemsScreen={<FilterResultNoItemsScreen />}
      /*  loading={
        isLoading && {
          type: 'noItems',
        }
      }*/
      rowsAreSelectable
      /* actionBarButtons={[
        {
          children: t('common:actions.export'),
          iconProp: ['fal', 'download'],
          onClick: exportData,
        },
      ]}
        */
    />
  );
};

export default HRTimeKeepingOverviewPage;
