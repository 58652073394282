import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Flex from '../../../../components/Flex';
import { Contact } from '../../../../models/Contact';
import { Project } from '../../../../models/Project';
import { PrioTheme } from '../../../../theme/types';
import useProjectsContext from '../../../projects/hooks/useProjectsContext';
import { getUserMe } from '../../../users/reducers';
import { fetchSpecialMailFolders } from '../../actions/actionControllers/specialMailFoldersActionController';
import { getProjectsSpecialMailFoldersState } from '../../../../apps/main/rootReducer';
import { CopyToProjectInformation } from '../../../../models/Message';
import { makePrioStyles } from '../../../../theme/utils';
import { Button, Dropdown } from '@prio365/prio365-react-library';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  copyInfo: {
    margin: theme.spacing.small,
    padding: `${theme.spacing.small}px 24px`,
    backgroundColor: theme.colors.base.yellow[80],
    color: theme.colors.application.typography.default,
    fontSize: theme.font.fontSize.small,
  },
  projectLinkButton: {
    '&:hover': {
      backgroundColor: theme.colors.base.yellow[50],
    },
  },
}));

interface CopyInformationPanelProps {
  copyBy: Contact;
  copyToProjects: CopyToProjectInformation[];
  isMe: boolean;
}

interface ExtendedCopyToProjectInformation extends CopyToProjectInformation {
  projectName: string;
  messageFolderName: string;
}

const generateProjectNames = (projects: Project[]) => {
  const projectCount: { [key: string]: number } = {};

  projects.forEach((project) => {
    if (!project) return;
    const name = `${project?.number ?? ''} ${project?.shortName ?? ''}`.trim();
    projectCount[name] = (projectCount[name] || 0) + 1;
  });

  const countText = (count: number) => (count > 1 ? ` (${count}×)` : '');
  const projectNames = Object.keys(projectCount).map(
    (name) => `${name}${countText(projectCount[name])}`
  );

  if (projectNames.length === 1) {
    return projectNames[0];
  }
  if (projectNames.length === 2) {
    return projectNames.join(' & ');
  }
  const allNamesButLast = projectNames.slice(0, -1);
  return `${allNamesButLast.join(', ')} & ${projectNames.at(-1)}`;
};

export const CopyInformationPanel: React.FC<CopyInformationPanelProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const { copyBy, copyToProjects, isMe } = props;
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const { getProjectById } = useProjectsContext();

  const userMe = useSelector(getUserMe);
  const specialMailFoldersById = useSelector(
    getProjectsSpecialMailFoldersState
  );

  const projectIds = copyToProjects
    ?.map((project) => project.projectId)
    .filter((projectId) => projectId);
  const projects = projectIds?.map((projectId) => {
    return getProjectById(projectId);
  });

  const extendedCopyToProjectInformation = copyToProjects?.map((project) => {
    return {
      projectName: `${getProjectById(project.projectId)
        ?.number} ${getProjectById(project.projectId)?.shortName}`,
      projectId: project.projectId,
      messageId: project.messageId,
      parentFolderId: project.parentFolderId,
    };
  }) as ExtendedCopyToProjectInformation[];

  const description =
    copyBy && !isMe
      ? t('mail:copyInformationPanel.copyBy.description', {
          firstName: copyBy?.firstName,
          lastName: copyBy?.lastName,
        })
      : copyToProjects
      ? t('mail:copyInformationPanel.copyToProjects.description', {
          projectNames: generateProjectNames(projects),
        })
      : '';
  const showLinkIcon =
    copyBy && !isMe
      ? userMe?.id === copyBy.contactId
      : copyToProjects && isMe
      ? true
      : false;

  const tooltip = isMe
    ? t('mail:copyInformationPanel.copyBy.tooltip')
    : t('mail:copyInformationPanel.copyToProjects.tooltip');
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const generateFolderId = async (info: ExtendedCopyToProjectInformation) => {
    // when parentFolder is inbox, replace id with 'inbox'
    const specialMailFolders = specialMailFoldersById[info.projectId];

    const inboxSpecialMailFolder = specialMailFolders?.['inboxFolder'];
    const isInboxFolder = inboxSpecialMailFolder?.id === info.parentFolderId;

    const parentFolderId = isInboxFolder ? 'inbox' : info.parentFolderId;

    return parentFolderId;
  };

  const handleOnClick = async (info: ExtendedCopyToProjectInformation) => {
    const parentFolderId = await generateFolderId(info);

    navigate(
      `/module/prio/projects/${info.projectId}/mail/${parentFolderId}/message/${info.messageId}/details`
    );
  };
  //#endregion

  //#region ------------------------------ Effects
  // bei jedem update von specialMailFolder wird alles neu getriggert.
  useEffect(() => {
    projectIds.forEach((projectId) => {
      if (!specialMailFoldersById[projectId]) {
        dispatch(fetchSpecialMailFolders(projectId));
      }
    });
  }, [projectIds, dispatch, specialMailFoldersById]);
  //#endregion

  return (
    <Flex.Row
      alignItems="center"
      childrenGap={theme.spacing.regular}
      className={classes.copyInfo}
    >
      <FontAwesomeIcon icon={['fal', 'copy']} />
      <Flex.Item flex={1}>{description}</Flex.Item>
      {showLinkIcon && copyToProjects?.length === 1 && (
        <Button
          className={classes.projectLinkButton}
          type="link"
          size="small"
          iconProp={['fal', 'arrow-up-right-from-square']}
          onClick={() => handleOnClick(extendedCopyToProjectInformation[0])}
          tooltip={tooltip}
        />
      )}
      {showLinkIcon && copyToProjects?.length > 1 && (
        <Dropdown
          dropdownWidth="auto"
          options={extendedCopyToProjectInformation.map((info) => {
            return {
              iconProp: ['fal', 'envelope'],
              suffixIconProp: ['fal', 'arrow-right'],
              label: `${info.projectName}`,
              value: info.messageId,
              onClick: () => handleOnClick(info),
            };
          })}
        >
          <Button
            className={classes.projectLinkButton}
            type="link"
            size="small"
            iconProp={['fal', 'arrow-up-right-from-square']}
          />
        </Dropdown>
      )}
    </Flex.Row>
  );
};
