import classNames from 'classnames';
import React, { CSSProperties, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'react-jss';
import { useSelector } from 'react-redux';
import {
  RootReducerState,
  getMessageCategoryColorMap,
} from '../../../../apps/main/rootReducer';
import i18n from '../../../../i18n';
import { MessageCenterMessage } from '../../../../models/Message';
import { ProjectNewsDto } from '../../../../models/Project';
import { MailCategoryColorName } from '../../../../models/Types';
import { PrioTheme } from '../../../../theme/types';
import { ShortDateTimeFormatString } from '../../../../util';
import AssignMailButton from './AssignMailButton';
import { MailListItemProps } from './MailListItem';
import MailListItemCategoryList from './MailListItemCategoryList';
import QuickActions from './QuickActions';

const mainRowStyle: (theme: PrioTheme) => CSSProperties = (theme) => ({
  minHeight: '1.5715em',
  paddingRight: theme.old.components.mailListItem.spacing,
  willChange: 'transform',
});

export const MailListItemHorizontal: React.FC<MailListItemProps> = (props) => {
  //#region ------------------------------ Defaults
  const {
    classes,
    classesOfCategoryList,
    message,
    projectId,
    isSendOrDraftFolder,
    isFavoritesView,
    colorMap: itemColorMap,
    mailFolderId,
    mailListSpacing,
    loading,
    getInboxFolderId,
    onDeselectMessage,
    projectNews = {},
  } = props;
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const colorMap = useSelector<
    RootReducerState,
    { [displayName: string]: MailCategoryColorName }
  >((state) =>
    isFavoritesView
      ? getMessageCategoryColorMap(state, projectId)
      : itemColorMap
  );

  const suffixMinWidth = useMemo(() => {
    switch (mailListSpacing) {
      case 'full': {
        return 265;
      }
      case 'middle': {
        return 225;
      }
      case 'tight': {
        return 200;
      }
    }
  }, [mailListSpacing]);

  const unreadAssignments = useMemo(
    () =>
      (projectNews?.[
        'messageAssignmentUnread'
      ] as unknown as ProjectNewsDto[]) ?? [],
    [projectNews]
  );

  const isMessageAssignmentUnread = useMemo(() => {
    return unreadAssignments.some(
      (news) => news.referenceObjectId === message.id
    );
  }, [unreadAssignments, message.id]);

  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <div
      className={classes.flexColumn}
      style={{ width: '100%', height: '100%' }}
    >
      {isFavoritesView && (
        <div
          className={classes.favoritesViewProjectName}
          style={{ flex: 1, display: 'flex', alignItems: 'center' }}
        >
          {(message as MessageCenterMessage).projectName}
        </div>
      )}
      <div className={classes.flexRow} style={{ flex: 1 }}>
        <div
          className={classes.flexRow}
          style={{
            ...mainRowStyle(theme),
            flex: 1,
            marginRight: theme.old.components.mailListItem.spacing,
          }}
        >
          <div
            className="prio-mail-list-item-mail-address"
            style={{ flex: 1, margin: 'auto' }}
          >
            {isSendOrDraftFolder
              ? message.toRecipients
                  ?.concat(message.ccRecipients)
                  .map((recipient) => recipient?.emailAddress.name)
                  .join('; ')
              : message.from?.emailAddress?.name}
          </div>
        </div>
        <div
          className={classes.flexRow}
          style={{
            ...mainRowStyle(theme),
            flex: 3,
          }}
        >
          <MailListItemCategoryList
            classes={classesOfCategoryList}
            styles={{ margin: 'auto' }}
            projectId={projectId}
            values={message?.categories ?? []}
            colorMap={colorMap}
            messageId={message?.id}
            loading={loading}
            tagListStyle={{
              position: 'unset',
            }}
            disableText
          />
          <div className={classes.flexRow} style={{ flex: 1 }}>
            <div
              className="prio-mail-list-item-subject"
              style={{
                overflow: 'visible',
                color: !message?.isRead
                  ? theme.old.palette.primaryColor
                  : theme.old.typography.colors.base,
                marginBlock: 'auto',
                marginRight: theme.old.components.mailListItem.spacing * 3,
              }}
            >
              {(message?.subject ?? '')?.trim().length > 0
                ? (message?.subject ?? '')?.trim() ?? ''
                : t('mail:messageDisplay.noSubject')}
            </div>
            {message.bodyPreview && (
              <div
                className="prio-mail-list-item-bodyPreview"
                style={{ marginBlock: 'auto' }}
              >
                {message.bodyPreview}
              </div>
            )}
          </div>
        </div>
        <div
          className={classNames(
            'prio-mail-list-item-receivedDateTime',
            classes.flexRow
          )}
          style={{ ...mainRowStyle(theme), minWidth: suffixMinWidth }}
        >
          <div style={{ flex: 1, margin: 'auto' }}>
            {ShortDateTimeFormatString(message.receivedDateTime, i18n.language)}
          </div>
          <QuickActions
            className={classes.quickActionsRoot}
            classes={classes}
            style={{ margin: 'auto' }}
            projectId={projectId}
            mailFolderId={mailFolderId}
            message={message}
            getInboxFolderId={getInboxFolderId}
            onDeselect={onDeselectMessage}
            loading={loading}
          />
          {projectId !== 'me' && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
                width: 26,
                height: 26,
                ...(isMessageAssignmentUnread && {
                  background: `radial-gradient(circle at 50% 50%, ${theme.colors.base.primary.default} 20%, transparent 70%)`,
                  borderRadius: '50%',
                  backgroundSize: 'contain',
                }),
              }}
            >
              <AssignMailButton
                classes={classes}
                projectId={projectId}
                message={message}
                loading={loading}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MailListItemHorizontal;
