import React, { useMemo } from 'react';
import {
  Routes as ReactRouterDomRoutes,
  Route,
  Navigate,
  useParams,
} from 'react-router-dom';
import MailProjectSubModule from '../../mail/components/projects/MailProjectSubModule';
import TimeRecordControllingSubModule from '../../timeRecords/components/TimeRecordControllingSubModule';
import TimeRecordSubModule from '../../timeRecords/components/TimeRecordSubModule';
import DocumentsSubModule from '../../documents/components/DocumentsSubModule';
import ProjectSummary from './ProjectSummary';
import ProjectSettings from './ProjectSettings';
import { useSelector } from 'react-redux';
import {
  getMyProjectIds,
  getProject,
  getUserMe,
  RootReducerState,
} from '../../../apps/main/rootReducer';
import { Project } from '../../../models/Project';
import InvoicesControllingSubModule from '../../accounting/components/InvoicesControllingSubModule';
import { ProjectId } from '../../../models/Types';
import * as Sentry from '@sentry/react';
import ProjectContactsSubModule from '../../contacts/components/ProjectContactsSubModule';
import FilterContextProvider from '../../../components/Filter/FilterContextProvider';
import {
  ProjectMember,
  ProjectMemberCalculatedData,
} from '../../../models/ProjectContacts';
import ProjectPlannerIFrame from './ProjectPlannerIFrame';
import PromanPage from './ProjectExtension/ExtensionForms/Proman/PromanPage';
import OpenExperiencePage from './ProjectExtension/ExtensionForms/OpenExperience/OpenExperiencePage';

const Routes = Sentry.withSentryReactRouterV6Routing(ReactRouterDomRoutes);

export const ProjectsModule: React.FC = () => {
  const { projectId } = useParams();
  return <MemoizedProjectsModule projectId={projectId} />;
};

interface MemoizedProjectsModuleProps {
  projectId: ProjectId;
}

export const MemoizedProjectsModule: React.FC<MemoizedProjectsModuleProps> =
  React.memo((props) => {
    //#region ------------------------------ Defaults
    const { projectId } = props;
    //#endregion

    //#region ------------------------------ States / Attributes / Selectors
    const project = useSelector<RootReducerState, Project>((state) =>
      getProject(state, projectId)
    );
    const userMe = useSelector(getUserMe);

    const myProjectIds = useSelector(getMyProjectIds);
    const myProjectIdsSet = useMemo(
      () => new Set(myProjectIds),
      [myProjectIds]
    );
    const isProjectMember = myProjectIdsSet.has(projectId);
    const officeRoles = userMe.prioData.officeRoles[project?.internalOfficeId];
    const isOfficeAdminOrAssistance =
      officeRoles?.includes('officeAdmin') ||
      officeRoles?.includes('officeAssistance');
    const isGlobalAdminOrAssistance =
      userMe.prioData.globalRoles.includes('globalAdmin') ||
      userMe.prioData.globalRoles.includes('globalAssistance');

    //#endregion

    if (!isProjectMember) {
      return (
        <Routes>
          <Route
            path="summary/*"
            element={<ProjectSummary projectId={projectId} />}
          />
          {(isOfficeAdminOrAssistance || isGlobalAdminOrAssistance) && (
            <Route
              path="contacts/*"
              element={
                <FilterContextProvider<
                  ProjectMember,
                  ProjectMemberCalculatedData
                >
                  searchType="projectMembers"
                  equalityFunction={(a, b) =>
                    a.data?.contactId === b.data?.contactId
                  }
                  customDefaultSearchParameters={[
                    {
                      parameterName: 'Data.ProjectId',
                      defaultValue: projectId,
                      defaultMethod: 'eq',
                    },
                  ]}
                >
                  <ProjectContactsSubModule projectId={projectId} />
                </FilterContextProvider>
              }
            />
          )}
          <Route path="settings">
            <Route path="*" element={<Navigate to="edit" replace />} />
            <Route
              path=":selectedList/*"
              element={<ProjectSettings projectId={projectId} />}
            />
          </Route>
        </Routes>
      );
    }

    return (
      <Routes>
        <Route
          path="contacts/*"
          element={
            <FilterContextProvider<ProjectMember, ProjectMemberCalculatedData>
              searchType="projectMembers"
              equalityFunction={(a, b) =>
                a.data?.contactId === b.data?.contactId
              }
              customDefaultSearchParameters={[
                {
                  parameterName: 'Data.ProjectId',
                  defaultValue: projectId,
                  defaultMethod: 'eq',
                },
              ]}
            >
              <ProjectContactsSubModule projectId={projectId} />
            </FilterContextProvider>
          }
        />
        {project?.sharedMailboxUserId && (
          <Route
            path="mail/*"
            element={<MailProjectSubModule projectId={projectId} />}
          />
        )}

        <Route
          path="timeRecordControlling/*"
          element={<TimeRecordControllingSubModule projectId={projectId} />}
        />
        <Route
          path="timeRecord/*"
          element={<TimeRecordSubModule projectId={projectId} />}
        />
        <Route
          path="planner/*"
          element={<ProjectPlannerIFrame projectId={projectId} />}
        />
        {project?.groupId && (
          <Route
            path="documents/*"
            element={<DocumentsSubModule projectId={projectId} />}
          />
        )}
        <Route path="proman/*" element={<PromanPage projectId={projectId} />} />
        <Route
          path="openExperience/*"
          element={<OpenExperiencePage projectId={projectId} />}
        />
        <Route
          path="invoiceControlling/*"
          element={<InvoicesControllingSubModule projectId={projectId} />}
        />
        <Route
          path="summary/*"
          element={
            <ProjectSummary
              projectId={projectId}
              isProjectMember={isProjectMember}
            />
          }
        />
        <Route path="settings">
          <Route path="*" element={<Navigate to="edit" replace />} />
          <Route
            path=":selectedList/*"
            element={<ProjectSettings projectId={projectId} />}
          />
        </Route>
      </Routes>
    );
  });

export default ProjectsModule;
