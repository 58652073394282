import { makePrioStyles } from '../../../../theme/utils';
import Timeline, {
  TimelineRef,
} from '../../../../components/Timeline/Timeline';
import {
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ContactId, OfficeId, ProjectId } from '../../../../models/Types';
import moment, { Moment } from 'moment';
import {
  TimelineGroup,
  TimelineItem,
} from '../../../../components/Timeline/types';
import classNames from 'classnames';
import useFilterContext from '../../../../components/Filter/hooks/useFilterContext';
import {
  AbsenceProposal,
  OfficeHoliday,
} from '../../../../models/AbsenceProposal';
import useContactsContext from '../../../contacts/hooks/useContactsProvider';
import {
  debounceFunction,
  distinct,
  distinctByProperty,
} from '../../../../util';
import { useDispatch, useSelector } from 'react-redux';
import {
  getOfficeHolidaysOfTimeRange,
  getSearchConfigBySearchType,
  getUserMe,
  RootReducerState,
} from '../../../../apps/main/rootReducer';
import { debouncedFetchOfficeHolidaysMe } from '../../actions';
import { TimelineBodySection } from '../../../../components/Timeline/utils';
import { apiFetchInternalProjectContacts } from '../../../projects/api';
import useOfficesContext from '../../../companies/hooks/useOfficesContext';
import { sortContactsHelper } from '../../../contacts/utils';
import { Contact } from '../../../../models/Contact';
import AbsenceTimelineGroupHeader from './AbsenceTimelineGroupHeader';
import AbsenceTimelineHeaderSectionItem from './AbsenceTimelineHeaderSectionItem';
import { generateSingleSearchStringCondition } from '../../../../components/Filter/utils';
import { useSearchParams } from 'react-router-dom';
import AbsenceTimelineItem from './AbsenceTimelineItem';
import { PrioTheme } from '../../../../theme/types';
import { useTheme } from 'react-jss';
import { calcTextWidth } from '../../../../util/calcTextWidth';
import { generateMultipleDaysAbsenceTitle } from '../../../timeAndLeaveManagement/components/Calendar/MonthEventComponent';
import { useTranslation } from 'react-i18next';
import { DefaultSearchParameterItem } from '../../../../components/Filter/types';

const useStyles = makePrioStyles((theme) => ({
  root: {
    flex: 1,
    overflow: 'hidden',
    '& .timeline-header-bar-section:not(:first-child):not(.prio-timeline-header-barItemSkip)':
      {
        borderLeft: 'none',
      },
    '& .prio-timeline-header-bar': {
      flex: 'unset',
    },
    '& .prio-timeline-header-topBar .prio-timeline-header-barItem': {
      justifyContent: 'unset',
      paddingLeft: 8,
      borderBottom: 'none',
    },
  },
  todaySection: {
    backgroundColor: theme.colors.application.background.hover,
  },
  weekendSection: {
    backgroundColor: theme.colors.application.background.light,
  },
  holidaySection: {
    backgroundColor: theme.colors.base.green[20],
  },
}));

const generateDateSearchString = (
  startTime: Moment,
  endTime: Moment,
  isHrContext?: boolean
): string => {
  if (isHrContext) {
    const from = generateSingleSearchStringCondition<AbsenceProposal>(
      'transformed.timespan',
      'ge',
      startTime.clone().subtract(1, 'year').toISOString(true).split('T')[0]
    );
    const to = generateSingleSearchStringCondition<AbsenceProposal>(
      'transformed.timespan',
      'le',
      endTime.clone().add(1, 'year').toISOString(true).split('T')[0]
    );
    return [from, to].join(' & ');
  }
  const geFrom = generateSingleSearchStringCondition<AbsenceProposal>(
    'data.from',
    'ge',
    startTime.clone().subtract(1, 'year').toISOString(true).split('T')[0]
  );
  const leFrom = generateSingleSearchStringCondition<AbsenceProposal>(
    'data.from',
    'le',
    endTime.clone().toISOString(true).split('T')[0]
  );
  const geTo = generateSingleSearchStringCondition<AbsenceProposal>(
    'data.to',
    'ge',
    startTime.clone().toISOString(true).split('T')[0]
  );
  const leTo = generateSingleSearchStringCondition<AbsenceProposal>(
    'data.to',
    'le',
    endTime.clone().add(1, 'year').toISOString(true).split('T')[0]
  );
  return [geFrom, leFrom, geTo, leTo].join(' & ');
};

const getAbsenceItemBackgroundColor = (
  absenceProposal: AbsenceProposal,
  theme: PrioTheme
) => {
  switch (absenceProposal.absenceType) {
    case 'annualLeave':
      if (absenceProposal.absenceState === 'requested') {
        return theme.colors.base.yellow.default;
      } else if (absenceProposal.absenceState === 'accepted') {
        return theme.colors.base.green.default;
      }
      return theme.old.palette.chromaticPalette.grey;
    case 'annualLeavePlanning':
      return theme.old.palette.chromaticPalette.grey;
    case 'overtimeCompensation':
      if (absenceProposal.absenceState === 'requested') {
        return `repeating-linear-gradient(45deg,${theme.colors.base.yellow.default},${theme.colors.base.yellow.default} 10px,${theme.old.palette.chromaticPalette.darkYellow} 10px,${theme.old.palette.chromaticPalette.darkYellow} 20px)`;
      } else if (absenceProposal.absenceState === 'accepted') {
        return `repeating-linear-gradient(45deg,${theme.colors.base.green.default},${theme.colors.base.green.default} 10px,${theme.old.palette.chromaticPalette.darkGreen} 10px,${theme.old.palette.chromaticPalette.darkGreen} 20px)`;
      } else if (absenceProposal.absenceState === 'planned') {
        return `repeating-linear-gradient(45deg,${theme.colors.base.primary.default},${theme.colors.base.primary.default} 10px,${theme.colors.application.background.default} 10px,${theme.colors.application.background.default} 20px)`;
      }
      break;
    case 'paidSpecialLeave':
      if (absenceProposal.absenceState === 'requested') {
        return `repeating-linear-gradient(45deg,${theme.colors.base.yellow.default},${theme.colors.base.yellow.default} 10px,${theme.old.palette.chromaticPalette.darkYellow} 10px,${theme.old.palette.chromaticPalette.darkYellow} 20px)`;
      } else if (absenceProposal.absenceState === 'accepted') {
        return `repeating-linear-gradient(45deg,${theme.colors.base.green.default},${theme.colors.base.green.default} 10px,${theme.old.palette.chromaticPalette.darkGreen} 10px,${theme.old.palette.chromaticPalette.darkGreen} 20px)`;
      } else if (absenceProposal.absenceState === 'planned') {
        return `repeating-linear-gradient(45deg,${theme.colors.base.primary.default},${theme.colors.base.primary.default} 10px,${theme.colors.application.background.default} 10px,${theme.colors.application.background.default} 20px)`;
      }
      break;
    case 'dayOfIllness':
      if (absenceProposal.absenceState === 'requested') {
        return `repeating-linear-gradient(90deg,${theme.colors.base.yellow.default},${theme.colors.base.yellow.default} 10px,${theme.old.palette.chromaticPalette.darkYellow} 10px,${theme.old.palette.chromaticPalette.darkYellow} 20px)`;
      } else if (absenceProposal.absenceState === 'accepted') {
        return `repeating-linear-gradient(90deg,${theme.colors.base.green.default},${theme.colors.base.green.default} 10px,${theme.old.palette.chromaticPalette.darkGreen} 10px,${theme.old.palette.chromaticPalette.darkGreen} 20px)`;
      } else if (absenceProposal.absenceState === 'planned') {
        return `repeating-linear-gradient(90deg,${theme.colors.base.primary.default},${theme.colors.base.primary.default} 10px,${theme.colors.application.background.default} 10px,${theme.colors.application.background.default} 20px)`;
      }
      break;
    default:
      if (absenceProposal.absenceState === 'requested') {
        return theme.colors.base.yellow.default;
      } else if (absenceProposal.absenceState === 'accepted') {
        return theme.colors.base.green.default;
      } else if (absenceProposal.absenceState === 'planned') {
        return theme.old.palette.chromaticPalette.grey;
      }
      break;
  }
  return theme.old.palette.chromaticPalette.grey;
};

const debouncedOnTimeRangeChange = debounceFunction(
  (
    startTime: Moment,
    endTime: Moment,
    isHrContext: boolean,
    searchString: string,
    onFetchAbsenceProposals: (searchString: string) => void
  ) => {
    const dateSearchString = generateDateSearchString(
      startTime,
      endTime,
      isHrContext
    );

    const _searchStringWithoutDate =
      searchString
        ?.split('&')
        ?.map((str) => str.trim())
        ?.filter(
          (str) =>
            !(
              str.includes('Data.From') ||
              str.includes('Data.To') ||
              str.includes('Transformed.Timespan')
            )
        )
        ?.join('&') ?? '';
    const _searchString =
      dateSearchString +
      `${!_searchStringWithoutDate ? '' : '&'}${_searchStringWithoutDate}`;

    onFetchAbsenceProposals(_searchString);
  },
  250
);

export interface AbsenceTimelineRef {
  /**
   * Method to set the time range of the timeline.
   * @param visibleTimeStart The start time of the visible time range
   * @param visibleTimeEnd The end time of the visible time range
   * @param startTime The start time of the timeline
   * @param endTime The end time of the timeline
   * @returns
   */
  setTimeRange: (
    visibleTimeStart: Moment,
    visibleTimeEnd: Moment,
    startTime: Moment,
    endTime: Moment
  ) => void;
}

export interface AbsenceTimelineGroup extends TimelineGroup {
  contact: Contact;
}

interface AbsenceTimelineItemItem extends TimelineItem {
  absenceProposal: AbsenceProposal;
}

interface AbsenceTimelineProps {
  className?: string;
  isHrContext?: boolean;
  customDefaultSearchParameters?: DefaultSearchParameterItem[];
  onAbsenceProposalClick?: (absenceProposal: AbsenceProposal) => void;
}

export const AbsenceTimeline = (
  props: AbsenceTimelineProps,
  ref: ForwardedRef<AbsenceTimelineRef>
) => {
  //#region ------------------------------ Defaults
  const {
    className,
    isHrContext,
    customDefaultSearchParameters,
    onAbsenceProposalClick,
  } = props;
  const classes = useStyles();

  const theme = useTheme<PrioTheme>();

  const { t } = useTranslation();

  const dispatch = useDispatch();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const timelineRef = useRef<TimelineRef>(null);

  const [searchParams] = useSearchParams();

  const searchFilterConfig = useSelector(
    (state: RootReducerState) =>
      getSearchConfigBySearchType(
        state,
        isHrContext ? 'absenceProposals' : 'publicAbsenceProposals'
      )?.searchableParameters ?? []
  );

  /**
   * searchString based on searchParams from url
   * searchString of context is null initially
   */
  const initialSearchString = useMemo(() => {
    const parameterToDefaultSearchParameterItem = searchFilterConfig
      .filter(
        (filter) =>
          filter.defaultValues?.length > 0 &&
          !(customDefaultSearchParameters ?? [])?.some(
            (parameter) => parameter.parameterName === filter.parameterName
          )
      )
      .map((filter) =>
        filter.defaultValues.map(({ defaultMethod, defaultValue }) => ({
          parameterName: filter.parameterName,
          defaultValue,
          defaultMethod,
        }))
      )
      .flat();
    const defaultParameterString = customDefaultSearchParameters
      .concat(parameterToDefaultSearchParameterItem)
      .map((parameter) =>
        generateSingleSearchStringCondition(
          parameter.parameterName as any,
          parameter.defaultMethod as any,
          parameter.defaultValue
        )
      )
      .join(' & ');
    return searchParams.get('s') ?? defaultParameterString;
  }, [searchParams, searchFilterConfig, customDefaultSearchParameters]);

  /**
   * DateTimeString of the start of the month based on searchParams from url
   */
  const searchParamStartMonth = useMemo(() => {
    const valueFromSearchParam = initialSearchString
      ?.split(' & ')
      ?.find((str) =>
        str.includes(`${isHrContext ? 'Transformed.Timespan' : 'Data.From'} ge`)
      )
      ?.split(' ')?.[2]
      ?.replace(/'/g, '')
      ?.substring(0, 7);

    if (valueFromSearchParam) {
      return (
        moment(valueFromSearchParam)
          .add(1, 'year')
          .toISOString(true)
          .substring(0, 7) + '-15'
      );
    }
    return moment().toISOString(true).slice(0, 7) + '-15';
  }, [initialSearchString, isHrContext]);

  /**
   * DateTimeString of the end of the month based on searchParams from url
   */
  const searchParamEndMonth = useMemo(() => {
    const valueFromSearchParam = initialSearchString
      ?.split(' & ')
      ?.find((str) =>
        str.includes(`${isHrContext ? 'Transformed.Timespan' : 'Data.To'} le`)
      )
      ?.split(' ')?.[2]
      ?.replace(/'/g, '')
      ?.substring(0, 7);

    if (valueFromSearchParam) {
      return (
        moment(valueFromSearchParam)
          .subtract(1, 'year')
          .toISOString(true)
          .substring(0, 7) + '-15'
      );
    }
    return moment().toISOString(true).slice(0, 7) + '-15';
  }, [initialSearchString, isHrContext]);

  const { contacts, getContactById } = useContactsContext();
  const { internalOffices } = useOfficesContext();
  const userMe = useSelector(getUserMe);
  const contact = useMemo(
    () => getContactById(userMe?.id),
    [getContactById, userMe]
  );

  const myOfficeIds = useMemo(
    () =>
      distinct([
        ...internalOffices.map(({ officeId }) => officeId),
        contact?.officeId,
      ]),
    [internalOffices, contact?.officeId]
  );

  const { data, searchString, fetchSearch } =
    useFilterContext<AbsenceProposal>();

  const officeHolidaysThreeMonthsTimeRange = useSelector<
    RootReducerState,
    OfficeHoliday[]
  >((state) =>
    getOfficeHolidaysOfTimeRange(
      state,
      moment(searchParamStartMonth)
        .subtract(1, 'month')
        .startOf('month')
        .toISOString(true)
        .substring(0, 7),
      moment(searchParamStartMonth)
        .add(1, 'month')
        .endOf('month')
        .toISOString(true)
        .substring(0, 7)
    )
  );

  const contactIdsBasedOnSearchString: ContactId[] = useMemo(() => {
    return (
      searchString
        ?.split('&')
        ?.find((str) => str.includes('ApplicantId'))
        ?.trim()
        ?.toLowerCase()
        ?.split(/\s+/)
        ?.slice(2)
        ?.join(' ')
        ?.replace(/'/g, '')
        ?.split(',') ?? []
    );
  }, [searchString]);

  const [internalProjectContactIds, setInternalProjectContactIds] = useState<
    ContactId[]
  >([]);

  const officeIdsBasedOnSearchString: OfficeId[] = useMemo(() => {
    return (
      searchString
        ?.split('&')
        ?.find((str) => str.includes('OfficeId'))
        ?.trim()
        ?.toLowerCase()
        ?.split(/\s+/)
        ?.slice(2)
        ?.join(' ')
        ?.replace(/'/g, '')
        ?.split(',') ?? []
    );
  }, [searchString]);

  /**
   * Contacts are used as groups in the timeline.
   * Contacts should be filtered based on the search string.
   */
  const filteredContacts = useMemo(() => {
    const prefilteredContacts = contacts.filter(({ contactType, officeId }) => {
      if (contactType === 'InternalContact') {
        return myOfficeIds.includes(officeId);
      }
      return false;
    });

    const filterOfficeIdsCondition = (contact: Contact) => {
      return (
        officeIdsBasedOnSearchString.length === 0 ||
        officeIdsBasedOnSearchString.includes(contact.officeId)
      );
    };

    const filterContactIdsCondition = (contact: Contact) => {
      return (
        contactIdsBasedOnSearchString.length === 0 ||
        contactIdsBasedOnSearchString.includes(contact.contactId)
      );
    };

    const filterProjectContactIdsCondition = (contact: Contact) => {
      return (
        internalProjectContactIds.length === 0 ||
        internalProjectContactIds.includes(contact.contactId)
      );
    };

    const filterConditions = [
      filterOfficeIdsCondition,
      filterContactIdsCondition,
      filterProjectContactIdsCondition,
    ];

    return prefilteredContacts
      .filter((contact) =>
        filterConditions.every((condition) => condition(contact))
      )
      .sort(sortContactsHelper);
  }, [
    contacts,
    internalProjectContactIds,
    myOfficeIds,
    officeIdsBasedOnSearchString,
    contactIdsBasedOnSearchString,
  ]);

  /**
   * Itemss for the timeline based on absence proposals in the data.
   */
  const items: AbsenceTimelineItemItem[] = useMemo(() => {
    return (data?.items ?? []).map<AbsenceTimelineItemItem>(
      ({ data: absenceProposal }) => {
        const startDateTime = moment(absenceProposal.from)
          .add(absenceProposal.firstIsHalfDay ? 12 : 0, 'hours')
          .toISOString(true);
        const endDateTime = moment(absenceProposal.to)
          .add(absenceProposal.lastIsHalfDay ? 12 : 24, 'hours')
          .toISOString(true);

        return {
          startDateTime,
          endDateTime,
          groupId: absenceProposal.applicantId,
          id: absenceProposal.absenceProposalId,
          title: absenceProposal.absenceType,
          absenceProposal,
        };
      }
    );
  }, [data]);

  const groups: AbsenceTimelineGroup[] = useMemo(() => {
    return distinctByProperty(
      filteredContacts.map<AbsenceTimelineGroup>((contact) => {
        const { contactId, firstName, lastName } = contact;
        return {
          id: contactId,
          title: `${firstName} ${lastName}`,
          contact,
        };
      }),
      'id'
    );
  }, [filteredContacts]);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleOnTimeRangeChange = useCallback(
    (
      visibleTimeStart: Moment,
      visibleTimeEnd: Moment,
      startTime: Moment,
      endTime: Moment
    ) => {
      debouncedOnTimeRangeChange(
        startTime,
        endTime,
        isHrContext,
        searchString ?? initialSearchString,
        fetchSearch
      );
    },
    [searchString, initialSearchString, isHrContext, fetchSearch]
  );

  /**
   * Method to calculate the className of sections of the timeline body.
   */
  const calcBodySectionClassName = useCallback(
    (section: TimelineBodySection) => {
      const date = section.date
        .clone()
        .add(section.date.utcOffset(), 'minutes');
      if (moment(date).isSame(moment(), 'day')) {
        return classes.todaySection;
      }
      if (
        officeHolidaysThreeMonthsTimeRange.some(
          (holiday) =>
            moment(holiday.date).isSameOrAfter(date, 'day') &&
            moment(holiday.date).isSameOrBefore(date, 'day')
        )
      ) {
        return classes.holidaySection;
      }
      if (date.day() === 0 || date.day() === 6) {
        return classes.weekendSection;
      }
      return undefined;
    },
    [classes, officeHolidaysThreeMonthsTimeRange]
  );

  const handleOnItemClick = useCallback(
    (item: AbsenceTimelineItemItem) => {
      if (onAbsenceProposalClick && isHrContext) {
        onAbsenceProposalClick(item.absenceProposal);
      }
    },
    [onAbsenceProposalClick, isHrContext]
  );
  //#endregion

  //#region ------------------------------ Components
  /**
   * Custom timeline header section component
   */
  const headerSectionRenderer = useCallback(
    (date: Moment, sectionType: 'major' | 'minor') => {
      if (sectionType === 'major') {
        return <div>{date.format('MMMM YYYY')}</div>;
      }
      return (
        <AbsenceTimelineHeaderSectionItem
          date={date}
          officeHolidays={officeHolidaysThreeMonthsTimeRange}
        />
      );
    },
    [officeHolidaysThreeMonthsTimeRange]
  );

  /**
   * Custom timeline group header component
   * Renders applicant name and its corresponding office name
   */
  const renderTimelineGroupHeader = useCallback(
    (group: AbsenceTimelineGroup) => {
      return <AbsenceTimelineGroupHeader group={group} />;
    },
    []
  );

  const renderItem = useCallback(
    (group: AbsenceTimelineGroup, item: AbsenceTimelineItemItem) => {
      const absenceProposal = item.absenceProposal;
      if (!absenceProposal) {
        return null;
      }
      return (
        <AbsenceTimelineItem
          absenceProposal={absenceProposal}
          isHrContext={isHrContext}
        />
      );
    },
    [isHrContext]
  );

  const calcItemStyle = useCallback(
    (group: AbsenceTimelineGroup, item: AbsenceTimelineItemItem) => {
      const absenceProposal = item.absenceProposal;

      if (!absenceProposal) {
        return {};
      }
      return {
        backgroundColor: getAbsenceItemBackgroundColor(absenceProposal, theme),
      };
    },
    [theme]
  );

  const renderSepperLabel = useCallback(
    (startDate: Moment, endDate: Moment) => {
      let label = startDate.format('MMMM YYYY');
      const isSameMonth = startDate.isSame(endDate, 'month');
      if (!isSameMonth) {
        const isSameYear = startDate.isSame(endDate, 'year');
        label = `${startDate.format(
          `MMMM ${isSameYear ? '' : 'YYYY'}`
        )} - ${endDate.format('MMMM YYYY')}`;
      }
      return (
        <div
          style={{
            width: !isSameMonth ? 200 : undefined,
          }}
        >
          {label}
        </div>
      );
    },
    []
  );

  const renderPopover = useCallback(
    (item: AbsenceTimelineItemItem) => {
      const absenceProposal = item.absenceProposal;

      const ignoreAbsenceType =
        !isHrContext && absenceProposal?.absenceType !== 'annualLeavePlanning';
      let content = '';
      if (moment(absenceProposal.from).isSame(absenceProposal.to, 'day')) {
        content = `${
          ignoreAbsenceType
            ? `${moment(absenceProposal.from).format('DD.MM.YYYY')} `
            : `${t(
                `absences:form.absenceTypes.${absenceProposal?.absenceType}`
              )} `
        }${
          absenceProposal.firstIsHalfDay === true
            ? `(${t('timeAndLeaveManagement:calendar.events.firstHalfOfDay')})`
            : absenceProposal.lastIsHalfDay === true
            ? `(${t('timeAndLeaveManagement:calendar.events.secondHalfOfDay')})`
            : ''
        }`;
      }
      content = generateMultipleDaysAbsenceTitle(
        absenceProposal,
        !isHrContext && absenceProposal?.absenceType !== 'annualLeavePlanning'
      );
      const textWidth = calcTextWidth(content, '15px');
      try {
        const element = document.querySelector(
          `#AbsenceTimeline .prio-timeline-item[data-item-id="${absenceProposal.absenceProposalId}"]`
        );
        if (element.clientWidth > textWidth + 23) {
          return null;
        }
      } catch (e) {}

      return (
        <div
          style={{
            padding: `0 8px`,
          }}
        >
          <AbsenceTimelineItem
            absenceProposal={absenceProposal}
            isHrContext={isHrContext}
          />
        </div>
      );
    },
    [isHrContext, t]
  );
  //#endregion

  //#region ------------------------------ Ref
  useImperativeHandle(ref, () => ({
    setTimeRange: (
      visibleTimeStart: Moment,
      visibleTimeEnd: Moment,
      startTime: Moment,
      endTime: Moment
    ) => {
      timelineRef.current?.setTimeRange(
        visibleTimeStart,
        visibleTimeEnd,
        startTime,
        endTime
      );
    },
  }));
  //#endregion

  //#region ------------------------------ Effects
  /**
   * Fetch office holidays of the time range of the timeline.
   * @see searchParamStartMonth
   * @see searchParamEndMonth
   */
  useEffect(() => {
    if (searchParamStartMonth && searchParamEndMonth) {
      const start = moment(searchParamStartMonth)
        .subtract(1, 'month')
        .startOf('month')
        .toISOString(true)
        .split('T')[0];
      const end = moment(searchParamEndMonth)
        .add(1, 'month')
        .endOf('month')
        .toISOString(true)
        .split('T')[0];
      if (contact?.officeId) {
        dispatch(debouncedFetchOfficeHolidaysMe(contact?.officeId, start, end));
      }
    }
  }, [searchParamStartMonth, searchParamEndMonth, contact, dispatch]);

  /**
   * Fetch all internal project contacts based on the projectIds in the search string
   * and set the internalProjectContactIds state.
   * The fetch is needed so group contacts can be filtered based on the projectIds in the search string.
   */
  useEffect(() => {
    if (searchString) {
      const projectIdsInSearchString = searchString
        ?.split('&')
        ?.find((str) => str.includes('ProjectId'))
        ?.trim()
        ?.toLowerCase()
        ?.split(/\s+/)
        ?.slice(2)
        ?.join(' ')
        ?.replace(/'/g, '')
        ?.split(',');
      const fetchAllInternalProjectContacts = async () => {
        const promises = projectIdsInSearchString?.map((projectId) =>
          apiFetchInternalProjectContacts(projectId as ProjectId)
        );
        const responseArray = await Promise.all(promises);
        const internalProjectContactIds = responseArray
          .filter(({ result }) => result.ok)
          .map((response) => response.data)
          .flat()
          .map(({ contactId }) => contactId);
        setInternalProjectContactIds(internalProjectContactIds);
      };
      if (projectIdsInSearchString?.length > 0) {
        fetchAllInternalProjectContacts();
      } else {
        setInternalProjectContactIds([]);
      }
    }
  }, [searchString]);
  //#endregion

  return (
    <div className={classNames(classes.root, className)}>
      <Timeline
        id="AbsenceTimeline"
        ref={timelineRef}
        initialVisibleTimeStart={moment(searchParamStartMonth).startOf('month')}
        initialVisibleTimeEnd={moment(searchParamEndMonth).endOf('month')}
        items={items}
        groups={groups}
        prefixWidth={200}
        onTimeRangeChange={handleOnTimeRangeChange}
        timelineResolutions={{
          headerBarResolutions: {
            top: 'month',
            bottom: 'day',
          },
          grid: 'halfDay',
        }}
        headerSectionRenderer={headerSectionRenderer}
        bodySectionClassName={calcBodySectionClassName}
        groupHeaderRenderer={renderTimelineGroupHeader}
        itemRenderer={renderItem}
        itemStyle={calcItemStyle}
        stepperLabelRenderer={renderSepperLabel}
        popoverRenderer={renderPopover}
        onItemClick={handleOnItemClick}
        rowHeight={60}
        stepMode="stepResolution"
        enableStepper
        disableAdd
        disableDrag
        disableResize
        disableRemove
        disableTopBar={searchParamStartMonth === searchParamEndMonth}
      />
    </div>
  );
};

export default forwardRef(AbsenceTimeline);
