import React, { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input, Select, notification, Tooltip } from 'antd';
import {
  Button,
  Checkbox,
  Drawer,
  PrioSpinner,
} from '@prio365/prio365-react-library';
import {
  ConfigurationKeys,
  EmployeeId,
  Gender,
} from '../../../../models/Types';
import moment, { Moment } from 'moment';
import { Employee } from '../../../../models/Employee';
import Flex from '../../../../components/Flex';
import { makePrioStyles } from '../../../../theme/utils';
import { colon } from '../../../../util/forms';
import CountryPicker from '../../../../components/CountryPicker';
import { PickerLocale } from 'antd/es/date-picker/generatePicker';
import CustomSingleDatePicker from '../../../../components/CustomSingleDatePicker';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';
import countries from 'i18n-iso-countries';
import countriesEn from 'i18n-iso-countries/langs/en.json';
import countriesDe from 'i18n-iso-countries/langs/de.json';
import i18n from '../../../../i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import eqauls from 'deep-equal';
import classNames from 'classnames';
import { parseFalsyValuesToUndefined } from '../../utils';
import {
  apiCreateMessageDraft,
  apiUpdateMessageDraft,
} from '../../../mail/api';
import { Configuration } from '../../../../models/Configuration';
import { apiFetchConfigurations } from '../../../settings/api';
import { defaultPhonePattern, defaultPhonePlaceholder } from '../../../../util';
import { emailPattern } from '../../../../hooks/useEmailValidation';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    fontSize: '14px',
  },
  gridContainer: {
    flex: 1,
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: theme.spacing.large,
  },
  gridColumn: {
    marginTop: theme.spacing.regular,
  },
  buttonRow: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: theme.spacing.large,
  },
  contactAddressRow: {
    width: 'fit-content',
    padding: `4px 0px`,
    '&:hover': {
      background: theme.colors.application.background.light,
      '& > svg': {
        visibility: 'visible',
      },
    },
  },
  contactInformationRow: {
    width: 'fit-content',
    marginBottom: theme.spacing.small,
    padding: `4px 8px`,
    '&:hover': {
      background: theme.colors.application.background.light,
      '& > svg': {
        visibility: 'visible',
      },
      '&.fa-enevelop': {
        visibility: 'visible',
      },
    },
  },
  additionalMarginForAddress: {
    margin: `0px -${theme.spacing.small}px`,
  },
  valueContactInformation: {
    marginLeft: theme.spacing.small,
    marginRight: theme.spacing.regular,
  },
  hoverActions: {
    cursor: 'pointer',
    visibility: 'hidden',
    '&:hover': {
      color: theme.colors.base.primary.default,
    },
  },
  header: {
    marginBottom: theme.spacing.regular,
    fontWeight: theme.font.fontWeight.bold,
    color: theme.colors.application.typography.muted,
  },
  form: {
    flex: 1,
    overflowX: 'hidden',
    overflowY: 'scroll',
  },
  fullWidth: {
    width: '100%',
  },
  heading: {
    marginBottom: theme.spacing.regular,
    fontSize: theme.font.fontSize.regular,
    color: theme.colors.application.typography.muted,
  },
  spacing: {
    marginBottom: '24px',
  },
  customDrawerContent: {
    paddingBottom: 0,
  },
  noInformationLabel: {
    color: theme.colors.application.typography.muted,
    paddingTop: theme.spacing.small,
  },
  labelHidden: {
    '& > div > label': {
      visibility: 'hidden',
    },
  },
}));

interface CoreDataFormModel {
  phone?: string;
  eMail?: string;
  street?: string;
  zip?: string;
  city?: string;
  countryCode?: string;
  gender?: Gender;
  nationality?: string;
  birthday?: Moment;
  allowShareBirthday?: boolean;
  birthplace?: string;
}

const coreDataFromEmployee: (employee: Employee) => CoreDataFormModel = (
  employee
) => ({
  phone: employee?.phone,
  eMail: employee?.eMail,
  street: employee?.street,
  zip: employee?.zip,
  city: employee?.city,
  countryCode: employee?.countryCode,
  gender: employee?.gender,
  nationality: employee?.nationality,
  birthday: employee?.birthday ? moment(employee?.birthday) : null,
  allowShareBirthday: employee?.allowShareBirthday,
  birthplace: employee?.birthplace,
});

interface PersonnelFileCoreDataFormProps {
  employeeId: EmployeeId;
  employee?: Employee;
  isSaving: boolean;
  datePickerLocale: PickerLocale;
  patchEmployee: (patchData: Employee) => Promise<void>;
}

export const PersonnelFileCoreDataForm: React.FC<
  PersonnelFileCoreDataFormProps
> = (props) => {
  const { employee, patchEmployee } = props;

  //#region ------------------------------ Defaults
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();

  countries.registerLocale(countriesDe);
  countries.registerLocale(countriesEn);
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [coreDataForm] = Form.useForm<CoreDataFormModel>();
  const [editDrawerVisible, setEditDrawerVisible] = useState<boolean>(false);

  const [isSaveButtonDisabled, setIsSaveButtonDisabled] =
    useState<boolean>(true);

  const [isCalendarViewOpen, setIsCalendarViewOpen] = useState<boolean>(false);
  const [configurations, setConfigurations] = useState<Configuration[] | null>(
    null
  );
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleOnEditClick = () => {
    setEditDrawerVisible(true);
  };

  const cancelEditCoreData = () => {
    coreDataForm.setFieldsValue(coreDataFromEmployee(employee));
    setEditDrawerVisible(false);
  };
  const submitCoreDataForm = () => {
    coreDataForm.submit();
    setEditDrawerVisible(false);
  };

  const handleFinishCoreData = async (coreData: CoreDataFormModel) => {
    const { eMail, phone, ...workaround } = coreData;
    const patchData: Employee = {
      ...workaround,
      eMail,
      phone,
      birthday: coreData.birthday?.toISOString(true).split('T')[0],
    };
    await patchEmployee(patchData);
  };

  const generateBirthdayLabel = () => {
    const allowWithOffice = employee.allowShareBirthday;
    const allowWithCompany = employee.allowShareBirthdayCompany;

    if (allowWithCompany && allowWithOffice)
      return 'Mit Abteilung und Firma geteilt';
    if (!allowWithCompany && allowWithOffice) return 'Mit Abteilung geteilt';
    if (allowWithCompany && !allowWithOffice) return 'Mit Firma geteilt';
    return null;
  };

  useEffect(() => {
    const loadConfigs = async () => {
      const { data } = await apiFetchConfigurations();
      if (data) setConfigurations(data);
    };
    loadConfigs();
  }, []);
  //#endregion

  //#region ------------------------------ Components

  const editDrawerContent = () => {
    return (
      <Form<CoreDataFormModel>
        form={coreDataForm}
        onFinish={handleFinishCoreData}
        layout="vertical"
        className={classes.form}
        onValuesChange={(_, values) => {
          const initialValues = coreDataFromEmployee(employee);

          const parsedInitialValues =
            parseFalsyValuesToUndefined(initialValues);
          const parsedValues = parseFalsyValuesToUndefined(values);

          if (!eqauls(parsedInitialValues, parsedValues)) {
            setIsSaveButtonDisabled(false);
          } else {
            setIsSaveButtonDisabled(true);
          }
        }}
      >
        <Flex.Column>
          <div className={classes.heading}>
            {t('hr:personnelFile.form.labels.address')}
          </div>
          <Flex.Row flex={1}>
            <Flex.Column flex={1}>
              <Form.Item
                name="street"
                label={t('hr:personnelFile.form.labels.street')}
                colon={colon}
              >
                <Input />
              </Form.Item>
            </Flex.Column>
          </Flex.Row>
          <Flex.Row
            flex={1}
            childrenGap={theme.spacing.small}
            className={classes.spacing}
          >
            <Flex.Column flex={1}>
              <Form.Item
                name="zip"
                label={t('hr:personnelFile.form.labels.zip')}
                colon={colon}
              >
                <Input />
              </Form.Item>
            </Flex.Column>
            <Flex.Column flex={2}>
              <Form.Item
                name="city"
                label={t('hr:personnelFile.form.labels.city')}
                colon={colon}
              >
                <Input />
              </Form.Item>
            </Flex.Column>
            <Flex.Column flex={2}>
              <Form.Item
                name="countryCode"
                label={t('hr:personnelFile.form.labels.countryCode')}
                colon={colon}
              >
                <CountryPicker />
              </Form.Item>
            </Flex.Column>
          </Flex.Row>
          <div className={classes.heading}>
            {t('hr:personnelFile.form.labels.contactInformation')}
          </div>
          <Flex.Row
            flex={1}
            childrenGap={theme.spacing.small}
            className={classes.spacing}
          >
            <Flex.Column flex={1}>
              <Form.Item
                name="eMail"
                label={t('hr:personnelFile.form.labels.eMail')}
                colon={colon}
                rules={[
                  {
                    message: t('companies:form.validation.invalidEmail'),
                    pattern: emailPattern,
                  },
                ]}
              >
                <Input type="email" />
              </Form.Item>
            </Flex.Column>
            <Flex.Column flex={1} style={{ marginBottom: theme.spacing.large }}>
              <Form.Item
                name="phone"
                label={t('hr:personnelFile.form.labels.phone')}
                colon={colon}
                rules={[
                  {
                    message: t('companies:form.validation.invalidPhoneNumber'),
                    pattern: configurations
                      ? configurations.find(
                          (config) =>
                            config.key === ConfigurationKeys.REGEX_PHONE
                        )
                        ? new RegExp(
                            configurations.find(
                              (config) =>
                                config.key === ConfigurationKeys.REGEX_PHONE
                            ).value
                          )
                        : defaultPhonePattern
                      : defaultPhonePattern,
                  },
                ]}
              >
                <Input
                  placeholder={
                    configurations?.find(
                      (config) =>
                        config.key === ConfigurationKeys.PLACEHOLDER_PHONE
                    )?.value ?? defaultPhonePlaceholder
                  }
                />
              </Form.Item>
            </Flex.Column>
          </Flex.Row>
          <div className={classes.heading}>
            {t('hr:personnelFile.form.labels.other')}
          </div>
          <Flex.Row flex={1} childrenGap={theme.spacing.small}>
            <Flex.Column flex={1}>
              <Form.Item
                name="gender"
                label={t('hr:personnelFile.form.labels.gender')}
                colon={colon}
              >
                <Select
                  placeholder={t('contacts:form.labels.gender')}
                  showSearch={false}
                  options={[
                    { value: 'male', label: t('contacts:form.gender.male') },
                    {
                      value: 'female',
                      label: t('contacts:form.gender.female'),
                    },
                    {
                      value: 'diverse',
                      label: t('contacts:form.gender.diverse'),
                    },
                  ]}
                />
              </Form.Item>
            </Flex.Column>
            <Flex.Column flex={1}>
              <Form.Item
                name="nationality"
                label={t('hr:personnelFile.form.labels.nationality')}
                colon={colon}
              >
                <CountryPicker />
              </Form.Item>
            </Flex.Column>
          </Flex.Row>
          <Flex.Row flex={1} childrenGap={theme.spacing.small}>
            <Flex.Column flex={1}>
              <Form.Item
                name="birthday"
                label={t('hr:personnelFile.form.labels.birthday')}
                colon={colon}
                className={classNames(classes.fullWidth, {
                  [classes.labelHidden]: isCalendarViewOpen,
                })}
              >
                <CustomSingleDatePicker
                  id="edit_personnelfile_id"
                  onFocusChange={setIsCalendarViewOpen}
                  focused={isCalendarViewOpen}
                  openDirection={'up'}
                  small={true}
                  regular={false}
                  twoMonths={false}
                  withFullScreenPortal={false}
                  daySize={30}
                  hideKeyboardShortcutsPanel={true}
                />
              </Form.Item>
            </Flex.Column>
            <Flex.Column flex={1}>
              <Form.Item
                name="birthplace"
                label={t('hr:personnelFile.form.labels.birthplace')}
                colon={colon}
              >
                <Input />
              </Form.Item>
            </Flex.Column>
          </Flex.Row>
          <Flex.Row flex={1} childrenGap={theme.spacing.small}>
            <Form.Item
              name="allowShareBirthday"
              label={t('hr:personnelFile.form.labels.allowShareBirthday')}
              colon={colon}
              valuePropName="checked"
              style={{ marginBottom: 0, flex: 1 }}
            >
              <Checkbox />
            </Form.Item>
            <Form.Item
              name="allowShareBirthdayCompany"
              label={t(
                'hr:personnelFile.form.labels.allowShareBirthdayCompany'
              )}
              colon={colon}
              valuePropName="checked"
              style={{
                marginBottom: 0,
                paddingLeft: theme.spacing.small,
                flex: 1,
              }}
            >
              <Checkbox />
            </Form.Item>
          </Flex.Row>
        </Flex.Column>
      </Form>
    );
  };

  const editDrawerFooter = () => {
    return (
      <Flex.Row justifyContent="flex-end" childrenGap={theme.spacing.small}>
        <Button type="link" onClick={cancelEditCoreData}>
          {t('common:actions.cancel')}
        </Button>

        <Button
          type="primary"
          disabled={isSaveButtonDisabled}
          onClick={submitCoreDataForm}
        >
          {t('common:save')}
        </Button>
      </Flex.Row>
    );
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    if (employee) {
      coreDataForm.setFieldsValue(coreDataFromEmployee(employee));
    }
  }, [employee, coreDataForm]);
  //#endregion

  return (
    <div className={classes.root}>
      <div className={classes.gridContainer}>
        <div className={classes.gridColumn}>
          <div className={classes.header}>
            {t('hr:personnelFile.form.labels.address')}
          </div>
          {employee?.street && employee?.zip && employee?.city && (
            <ContactInformationWithClickableAction
              type="address"
              contactInformation={
                <div>
                  {employee?.street && <div>{employee?.street}</div>}
                  {(employee?.zip || employee?.city) && (
                    <div>
                      {employee?.zip} {employee.city}
                    </div>
                  )}
                  {employee?.countryCode && (
                    <div>
                      {
                        Object.entries<string | string[]>(
                          countries?.getNames(i18n.language.substring(0, 2))
                        )?.find((x) => x[0] === employee?.countryCode)?.[1]
                      }
                    </div>
                  )}
                </div>
              }
            />
          )}
          {!employee?.street &&
            !employee?.zip &&
            !employee?.city &&
            !employee?.federalStateCode &&
            !employee?.countryCode && (
              <div className={classes.noInformationLabel}>
                {t('hr:personnelFile.form.labels.noInformation')}
              </div>
            )}
        </div>
        <div className={classes.gridColumn}>
          <div style={{ height: '40px' }}></div>
          {employee?.eMail && (
            <ContactInformationWithClickableAction
              type="email"
              contactInformation={employee?.eMail}
            />
          )}
          {employee?.phone && (
            <ContactInformationWithClickableAction
              type="phone"
              contactInformation={employee?.phone}
            />
          )}
          {!employee?.eMail && !employee?.phone && (
            <div className={classes.noInformationLabel}>
              {t('hr:personnelFile.form.labels.noInformation')}
            </div>
          )}
        </div>
        <Flex.Column
          childrenGap={theme.spacing.small}
          className={classes.gridColumn}
        >
          <div className={classes.header}>
            {t('hr:personnelFile.form.labels.other')}
          </div>
          {(employee?.gender === 'male' ||
            employee?.gender === 'female' ||
            employee?.gender === 'diverse') && (
            <div>{`${t('hr:personnelFile.form.labels.gender')}: ${
              employee?.gender === 'male'
                ? t('common:gender.male')
                : employee?.gender === 'female'
                ? t('common:gender.female')
                : t('common:gender.diverse')
            }`}</div>
          )}
          {employee.nationality && (
            <div>{`${t(
              'hr:personnelFile.form.labels.nationality'
            )}: ${Object.entries<string | string[]>(
              countries?.getNames(i18n.language.substring(0, 2))
            )?.find((x) => x[0] === employee?.nationality)?.[1]}`}</div>
          )}
          {employee?.birthplace && (
            <div>
              {`${t(
                'hr:personnelFile.form.labels.birthplace'
              )}: ${employee?.birthplace}`}
            </div>
          )}
          {!(
            employee?.birthday === '0001-01-01' || employee?.birthday === null
          ) && (
            <div>
              <div>
                {`${t('hr:personnelFile.form.labels.birthday')}: ${moment(
                  employee?.birthday
                ).format('DD.MM.YYYY')}`}
              </div>
              {!!employee?.birthday &&
                (employee.allowShareBirthday ||
                  employee.allowShareBirthdayCompany) && (
                  <Flex.Row
                    alignItems="center"
                    childrenGap={theme.spacing.small}
                  >
                    <FontAwesomeIcon
                      icon={['fal', 'check']}
                      style={{ paddingLeft: theme.spacing.regular }}
                    />
                    <div style={{ fontSize: theme.font.fontSize.extraSmall }}>
                      {generateBirthdayLabel()}
                    </div>
                  </Flex.Row>
                )}
            </div>
          )}

          {!(
            employee?.gender === 'male' ||
            employee?.gender === 'female' ||
            employee?.gender === 'diverse'
          ) &&
            !employee.nationality &&
            employee?.birthday === '0001-01-01' && (
              <div className={classes.noInformationLabel}>
                {t('hr:personnelFile.form.labels.noInformation')}
              </div>
            )}
        </Flex.Column>
      </div>
      <div className={classes.buttonRow}>
        <Button type="primary" onClick={handleOnEditClick}>
          {t('common:edit')}
        </Button>
      </div>
      <Drawer
        title={t('hr:personnelFile.editDrawer.personalData')}
        visible={editDrawerVisible}
        onClose={() => setEditDrawerVisible(false)}
        children={editDrawerContent()}
        footer={editDrawerFooter()}
        contentClassName={classes.customDrawerContent}
      />
    </div>
  );
};

export default PersonnelFileCoreDataForm;

interface ContactInformationWithClickableActionProps {
  type: 'address' | 'email' | 'phone';
  contactInformation: ReactNode | string;
}

const ContactInformationWithClickableAction: React.FC<
  ContactInformationWithClickableActionProps
> = (props) => {
  //#region ------------------------------ Defaults
  const { type, contactInformation } = props;
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const parsedAddress =
    type === 'address' && typeof contactInformation === 'object'
      ? contactInformation?.['props']?.children
          ?.map((row) => row?.props?.children)
          ?.map((item) => {
            return typeof item === 'string' ? [item] : item;
          })
          ?.map((item) => {
            return item?.join('');
          })
          ?.join('\n')
      : null;
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const sendMessageToContact = async (email: string) => {
    setIsCreating(true);
    const { data: message } = await apiCreateMessageDraft('me');
    if (message) {
      message.toRecipients = [
        {
          emailAddress: {
            name: '',
            address: email,
          },
        },
      ];
      await apiUpdateMessageDraft('me', message.id, message);
      const width = window.screen.availWidth / 2;
      const height = window.screen.availHeight * 0.6;
      window.open(
        `/view/me/composer/${message.id}`,
        '_blank',
        `width=${width},height=${height},top=${height / 4}`
      );
    } else {
      notification.open({
        message: t('common:error'),
        description: t('mail:errorMessages.messages.createDraftError'),
      });
    }
    setIsCreating(false);
  };

  const onCopyClick = () => {
    navigator.clipboard.writeText(
      type === 'address' ? parsedAddress : (contactInformation as string)
    );
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <Flex.Row
      alignItems="center"
      className={classNames(
        type === 'address'
          ? classes.contactAddressRow
          : classes.contactInformationRow,
        {
          [classes.additionalMarginForAddress]: type === 'address',
        }
      )}
    >
      {type !== 'address' && (
        <FontAwesomeIcon
          icon={['fal', type === 'email' ? 'envelope' : 'phone']}
          width={20}
          color={theme.colors.base.primary.default}
        />
      )}
      <span className={classes.valueContactInformation}>
        {contactInformation}
      </span>
      <Tooltip overlay={t('hr:personnelFile.form.actions.copy')}>
        <FontAwesomeIcon
          icon={['fal', isCopied ? 'check' : 'copy']}
          width={20}
          color={theme.colors.application.typography.default}
          className={classes.hoverActions}
          onClick={onCopyClick}
        />
      </Tooltip>
      {type === 'email' ? (
        isCreating ? (
          <PrioSpinner size="small" />
        ) : (
          <Tooltip overlay={t('hr:personnelFile.form.actions.sendMessage')}>
            <FontAwesomeIcon
              icon={['fal', 'paper-plane']}
              width={20}
              color={theme.colors.application.typography.default}
              className={classes.hoverActions}
              onClick={() => sendMessageToContact(contactInformation as string)}
            />
          </Tooltip>
        )
      ) : null}
    </Flex.Row>
  );
};
