import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Col, Popover, Row } from 'antd';
import { Button, Drawer } from '@prio365/prio365-react-library';
import Meta from 'antd/lib/card/Meta';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Flex from '../../../../components/Flex';
import {
  AvailableProjectExtension,
  CreateProjectExtension,
} from '../../../../models/ProjectExtension';
import { ProjectId } from '../../../../models/Types';
import { makePrioStyles } from '../../../../theme/utils';
import { getSvgIconPathByType } from '../../../../util/icon';
import { apiFetchAvailableProjectExtensionsForProject } from '../../api';
import ProjectExtensionDrawer, {
  ProjectExtensionDrawerTabs,
} from './ProjectExtensionDrawer';
import SvgIcon from '../../../../components/SvgIcon';

const useStyles = makePrioStyles((theme) => ({
  root: {
    '& > div': {
      '& > div': {
        '& > div': {
          '& > div': {
            display: 'flex',
            flexDirection: 'column',
          },
        },
      },
    },
    '& .ant-drawer-body': {
      display: 'flex',
      flexDirection: 'column',
    },
    height: '100%',
  },
  colGrid: {
    padding: theme.old.spacing.baseSpacing,
  },
  description: {
    margin: 'auto 0',
    '& p': {},
    '& h5': {
      color: theme.old.palette.chromaticPalette.lightGrey,
    },
  },
  secondaryText: {
    color: theme.old.palette.chromaticPalette.lightGrey,
  },
  grid: {
    display: 'flex',
    flexFlow: 'row wrap',
  },
  add: {
    position: 'absolute',
    margin: 0,
    width: '100%',
    height: '100%',
    opacity: 0,
    '&:hover': {
      opacity: 1,
    },
  },
  info: {
    position: 'absolute',
    margin: 0,
    width: '100%',
    height: '100%',
  },
  container: {},
  widget: {
    backgroundColor: theme.old.palette.backgroundPalette.content,
    boxShadow: theme.old.palette.boxShadow.regular,
    height: '100%',
    width: '100%',
    display: 'flex',
    minHeight: '150px',
    flexFlow: 'column',
    textAlign: 'center',
  },
  widgetInner: {
    width: '100%',
    height: '100%',
  },
  widgetInnerOverlay: {
    width: '100%',
    height: '100%',
    opacity: '0.9',
    backgroundColor: theme.old.palette.backgroundPalette.sub,
  },
  widgetOuterOverlayBackground: {
    width: '100%',
    height: '100%',
    paddingRight: theme.old.spacing.baseSpacing * 2,
    paddingBottom: theme.old.spacing.baseSpacing * 2,
  },

  disabled: {
    backgroundColor: theme.old.palette.backgroundPalette.sub,
  },
  selected: {
    backgroundColor: theme.old.palette.chromaticPalette.green,
    opacity: 0.5,
  },
  icon: {
    margin: '10px auto',
    fontSize: '24pt',
    opacity: 1,
  },
  iconAdd: {
    margin: 'auto auto',
    fontSize: '24pt',
    color: theme.old.palette.chromaticPalette.blue,
  },
  leftMargin: {
    marginLeft: '2px',
  },
}));

export interface AvailableProjectExtensionSelected
  extends AvailableProjectExtension {
  selected?: boolean;
}

interface AddProjectExtensionDrawerProps {
  projectId: ProjectId;
  addProjectExtensionDrawerVisible: boolean;
  setAddProjectExtensionDrawerVisible: (boolean) => void;
  reloadProjectExtension: () => void;
}

export const AddProjectExtensionDrawer: React.FC<
  AddProjectExtensionDrawerProps
> = (props) => {
  //#region ------------------------------ Defaults
  const {
    addProjectExtensionDrawerVisible,
    setAddProjectExtensionDrawerVisible,
    reloadProjectExtension,
    projectId,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const [projectExtensionDrawerVisible, setProjectExtensionDrawerVisible] =
    useState<boolean>(false);
  const [selectedAddProjectExtentions, setSelectedAddProjectExtentions] =
    useState<CreateProjectExtension>(null);
  const [projectExtensionDrawerTab, setProjectExtensionDrawerTab] =
    useState<ProjectExtensionDrawerTabs>('settings');

  const [availableProjectExtentions, setAvailableProjectExtentions] = useState<
    AvailableProjectExtensionSelected[]
  >([]);
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors

  //#endregion

  //#region ------------------------------ Methods / Handlers
  const onClose = () => {
    setAddProjectExtensionDrawerVisible(false);
  };
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  useEffect(() => {
    if (addProjectExtensionDrawerVisible) {
      const loadConfigs = async () => {
        const { data } =
          await apiFetchAvailableProjectExtensionsForProject(projectId);
        if (data) {
          let availableExtensions: AvailableProjectExtensionSelected[] =
            data.map((extension) => {
              return { ...extension, selected: false };
            });
          setAvailableProjectExtentions(availableExtensions);
        }
      };
      loadConfigs();
    }
  }, [addProjectExtensionDrawerVisible, projectId]);

  return (
    <Drawer
      closable={true}
      onClose={onClose}
      title={t('projects:addIn.extensions.addProjectExtensionTitle')}
      subheader={t('projects:addIn.extensions.addProjectExtensionDescription')}
      visible={addProjectExtensionDrawerVisible}
      footer={
        <Flex.Column justifyContent="flex-end">
          <Flex.Row justifyContent="flex-end">
            <Button
              onClick={() => {
                onClose();
              }}
            >
              {t('projects:createProject.buttons.back')}
            </Button>
          </Flex.Row>
        </Flex.Column>
      }
    >
      <Flex.Column>
        <Row gutter={[16, 16]} className={classes.grid}>
          {availableProjectExtentions
            .filter(
              (aExtensions) =>
                aExtensions.projectExtensionType === 'sharepoint' ||
                aExtensions.projectExtensionType === 'sharedMailbox' ||
                aExtensions.projectExtensionType === 'planner' ||
                aExtensions.projectExtensionType === 'jira' ||
                // aExtensions.projectExtensionType === 'proman' ||
                aExtensions.projectExtensionType === 'openExperience'
            )
            .map((availableExtension, index) => (
              <Col
                span={8}
                className={classes.colGrid}
                key={availableExtension.projectExtensionType + index}
              >
                <Card
                  hoverable={
                    availableExtension.projectExtensionAvailableState ===
                      'available' && availableExtension.selected === false
                  }
                  className={
                    availableExtension.selected
                      ? classes.selected
                      : availableExtension.projectExtensionAvailableState ===
                        'available'
                      ? ''
                      : classes.disabled
                  }
                  onClick={() => {
                    if (
                      availableExtension.projectExtensionAvailableState ===
                      'available'
                    ) {
                      setProjectExtensionDrawerVisible(true);
                      setSelectedAddProjectExtentions({
                        projectExtensionType:
                          availableExtension.projectExtensionType,
                        displayName: '',
                        value: '',
                      });
                    }
                  }}
                  cover={
                    <SvgIcon
                      width={60}
                      height={60}
                      path={getSvgIconPathByType(
                        availableExtension?.projectExtensionType
                      )}
                      style={{ marginTop: '20px' }}
                    ></SvgIcon>
                  }
                >
                  <Meta
                    title={t(
                      `projects:addIn.${availableExtension?.projectExtensionType}`
                    )}
                    description={
                      availableExtension.projectExtensionAvailableState !==
                        'available' && availableExtension.stateExplanation ? (
                        <Flex.Row>
                          {t(`projects:addIn.notAvailable`)}{' '}
                          <Popover
                            content={availableExtension.stateExplanation}
                          >
                            <FontAwesomeIcon
                              icon={['fal', 'info-circle']}
                              className={classes.leftMargin}
                            />
                          </Popover>
                        </Flex.Row>
                      ) : (
                        t(`projects:addIn.addAddIn`)
                      )
                    }
                  />
                </Card>
              </Col>
            ))}
        </Row>
      </Flex.Column>

      <ProjectExtensionDrawer
        projectExtensionDrawerVisible={projectExtensionDrawerVisible}
        setProjectExtensionDrawerVisible={setProjectExtensionDrawerVisible}
        projectExtension={null}
        addProjectExtension={selectedAddProjectExtentions}
        projectExtensionDrawerTab={projectExtensionDrawerTab}
        setProjectExtensionDrawerTab={setProjectExtensionDrawerTab}
        projectId={projectId}
        projectExtensionCreated={() => {
          setProjectExtensionDrawerVisible(false);
          setAddProjectExtensionDrawerVisible(false);
          if (reloadProjectExtension) {
            reloadProjectExtension();
          }
        }}
        projectExtensionUpdated={() => {
          //nothing to do
        }}
        reloadProjectExtension={reloadProjectExtension}
      />
    </Drawer>
  );
};

export default AddProjectExtensionDrawer;
