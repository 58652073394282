import React, { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Menu } from 'antd';
import classNames from 'classnames';
import { makePrioStyles } from '../../../../../../theme/utils';
import Flex from '../../../../../../components/Flex';
import { ProjectId } from '../../../../../../models/Types';
import { useSelector } from 'react-redux';
import {
  getProject,
  RootReducerState,
} from '../../../../../../apps/main/rootReducer';
import { Project } from '../../../../../../models/Project';
import { ProjectExtensionType } from '../../../../../../models/ProjectExtension';
import SvgIcon from '../../../../../../components/SvgIcon';
import { getSvgIconPathByType } from '../../../../../../util/icon';
import { apiFetchSettings } from '../../../../../settings/api';
import {
  OpenExperienceSettings,
  Setting,
  SettingKey,
} from '../../../../../../models/Settings';

const useStyles = makePrioStyles((theme) => ({
  root: { height: '100%' },
  menu: {
    flex: 1,
    maxWidth: '280px',
    width: '280px',
    overflowY: 'auto',
    backgroundColor: theme.colors.application.background.default,
    '& ul': {
      background: 'none',
    },
  },
  rowContent: {
    overflow: 'hidden',
    height: '100%',
    backgroundColor: theme.colors.application.background.default,
  },
  content: {
    flex: 1,
    padding: theme.spacing.regular,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    lineHeight: '44px!important',
    padding: `${theme.old.spacing.unit(2)}px ${
      theme.old.spacing.defaultPadding
    }px`,
    margin: '0!important',
  },
  iframeWrapper: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.colors.application.background.default,
    borderRadius: theme.borderRadius.small,
    boxShadow: theme.boxShadow.regular,
    overflow: 'hidden',
  },
  iframeView: {
    width: '100%',
    height: '100%',
    border: 'none',
  },
  icon: {
    marginTop: theme.spacing.large,
  },
}));

const serviceUrls = {
  oa: 'https://openexperience.de/onlineBauabnahme/project/',
  fd2: 'https://openexperience.de/baufoto/project/',
  qm: 'https://openexperience.de/checklist/#/projects/',
  ot2: 'https://openexperience.de/ot2/#/projects/',
};

interface OpenExperiencePageProps {
  projectId: ProjectId;
  className?: string;
}
const OpenExperiencePage: React.FC<OpenExperiencePageProps> = ({
  projectId,
  className,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [selectedService, setSelectedService] = useState<string | null>(null);
  const [openExperienceSettings, setOpenExperienceSettings] =
    useState<OpenExperienceSettings | null>(null);

  const project = useSelector<RootReducerState, Project>((state) =>
    getProject(state, projectId)
  );
  const getProjectExtensionMe = (
    project: Project,
    type: ProjectExtensionType
  ) => {
    if (!project) {
      return undefined;
    }
    return project.projectExtensionMeDtos?.find(
      (ext) => ext.projectExtensionType === type
    );
  };

  const openExperienceProjectExtension = useMemo(() => {
    return getProjectExtensionMe(project, 'openExperience');
  }, [project]);

  const projectKey = openExperienceProjectExtension?.key;
  const customerId = openExperienceProjectExtension?.value;

  useEffect(() => {
    const fetchDefaultSettings = async () => {
      var projectExtensionId =
        openExperienceProjectExtension?.projectExtensionId;
      var setting: SettingKey = {
        keys: ['ProjectExtension_OpenExperience_Settings'],
        scopeId: projectExtensionId,
        eSettingScope: 'projectExtension',
      };
      const { data } = await apiFetchSettings([setting]);
      if (data) {
        var settings: Setting = data[0];
        var openExperienceSettings: OpenExperienceSettings = JSON.parse(
          settings.jsonValue
        );
        setOpenExperienceSettings(openExperienceSettings);
      }
    };
    fetchDefaultSettings();
  }, [projectKey, openExperienceProjectExtension?.projectExtensionId]);

  return (
    <Flex.Column className={classNames(classes.root, className)}>
      <Flex.Row className={classes.rowContent}>
        <Flex.Column
          flexGrow={1}
          className={classNames('prio-module-navigation', classes.menu)}
        >
          <Typography.Title className={classes.title}>
            {t('projects:addIn.extensions.openExperience.page.menuTitle')}
          </Typography.Title>
          <Menu
            mode="inline"
            selectedKeys={[selectedService]}
            onClick={({ key }) => setSelectedService(key)}
          >
            {openExperienceSettings?.EnabledServices?.map((serviceKey) => (
              <Menu.Item key={serviceKey}>
                {t(
                  `projects:addIn.extensions.openExperience.services.${serviceKey}`
                )}
              </Menu.Item>
            ))}
          </Menu>
        </Flex.Column>

        <div className={classes.content}>
          {selectedService && projectKey && customerId ? (
            <div className={classes.iframeWrapper}>
              <iframe
                className={classes.iframeView}
                src={`${serviceUrls[selectedService]}${customerId}-${projectKey}`}
                title={selectedService}
              />
            </div>
          ) : (
            <>
              <SvgIcon
                width={100}
                height={100}
                path={getSvgIconPathByType('openExperience')}
              />
              <div className={classes.icon}>
                {t('projects:addIn.extensions.openExperience.page.select')}
              </div>
            </>
          )}
        </div>
      </Flex.Row>
    </Flex.Column>
  );
};

export default OpenExperiencePage;
