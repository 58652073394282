import React, { useCallback, useEffect, useState } from 'react';
import Flex from '../../../components/Flex';
import { Modal } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { makePrioStyles } from '../../../theme/utils';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  getActiveProject,
  getProjectDistributionLists,
  RootReducerState,
} from '../../../apps/main/rootReducer';
import { EmailString } from '../../../models/Types';
import {
  ProjectDistributionContact,
  ProjectDistributionList,
} from '../../../models/ProjectDistributionList';
import useDebounce from '../../../hooks/useDebounce';
import { fetchProjectDistribustionList } from '../../projects/actions';
import AddressSelectMail from './AddressSelectMail';
import classNames from 'classnames';
import { PrioTheme } from '../../../theme/types';
import ProjectDistributionListTable from '../../projects/components/ProjectDistributionListTable';
import { VirtualListItemOnRowProps } from '@prio365/prio365-react-library/lib/VirtualList/components/VirtualListItem';

interface AddressBarProps {
  className?: string;
  type: 'to' | 'cc' | 'bcc' | 'from';
  value: EmailString[];
  onChange: (value: EmailString[]) => void;
  focus?: boolean;
  tabIndex?: number;
}

const useStylesAddressBar = makePrioStyles((theme: PrioTheme) => ({
  root: {},
  button: {
    backgroundColor: theme.old.components.addressBar.backgroundColor,
    minWidth: 64,
  },
  icon: {
    color: 'currentColor!important',
  },
  modal: {
    '& .ant-modal-content': {
      maxHeight: '60vh',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      '& .ant-modal-body': {
        padding: 0,
        overflow: 'hidden',
      },
    },
  },
  distributionList: {
    height: 475,
    padding: 16,
  },
  distributionFilter: {
    padding: `${theme.old.spacing.unit(1)}px ${theme.old.spacing.unit(1)}px`,
  },
}));

export const AddressBar: React.FC<AddressBarProps> = (props) => {
  //#region ------------------------------ Defaults
  const classes = useStylesAddressBar(props);

  const { className, type, value, onChange, focus, tabIndex } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const projectId = useSelector(getActiveProject);
  const isMe = projectId === 'me';

  const projectDistributionLists = useSelector<
    RootReducerState,
    ProjectDistributionList[]
  >((state) => (isMe ? [] : getProjectDistributionLists(state, projectId)));

  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const [selectedLists, setSelectedLists] = useState<EmailString[]>([]);

  const debouncedModalVisible = useDebounce(modalVisible, 10);

  const [selectedDistributionLists, setSelectedDistributionLists] = useState<
    ProjectDistributionList[]
  >([]);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const changeHandler = useCallback(
    (value: EmailString[]) => {
      onChange(value.filter((mail) => mail.replace(/\s/g, '')));
    },
    [onChange]
  );
  const handleSelectionChange = (lists: ProjectDistributionList[]) => {
    const contacts: ProjectDistributionContact[] = [].concat(
      ...lists.map((list) => list.projectDistributionContacts)
    );
    setSelectedLists(contacts.map((contact) => contact.eMail));
  };

  const handleOnOkDistributionList = () => {
    changeHandler(
      selectedLists
        .filter(
          (contact, index, self) =>
            index === self.findIndex((c) => contact === c) &&
            !value.find((v) => v === contact)
        )
        .concat(value)
    );
    setModalVisible(false);
  };

  const handleOnCancelDistributionList = () => {
    setSelectedLists([]);
    setModalVisible(false);
  };

  const handleOnRow: (
    item: ProjectDistributionList,
    index: number,
    selectedItems: ProjectDistributionList[]
  ) => VirtualListItemOnRowProps<null, null> = useCallback(
    (item, index, selectedItems) => {
      if (
        selectedItems.find(
          (i) => i.projectDistributionListId === item.projectDistributionListId
        )
      ) {
        return {
          onClick: (event) => {
            event.preventDefault();
            setSelectedDistributionLists(
              selectedItems.filter(
                (i) =>
                  i.projectDistributionListId !== item.projectDistributionListId
              )
            );
          },
        };
      }
      return {
        onClick: (event) => {
          event.preventDefault();
          setSelectedDistributionLists([item, ...selectedItems]);
        },
      };
    },
    []
  );

  //#endregion

  useEffect(() => {
    if (selectedDistributionLists.length > 0) {
      handleSelectionChange(selectedDistributionLists);
    }
  }, [selectedDistributionLists]);

  return (
    <Flex.Row className={classNames(classes.root, className)}>
      <Button className={classes.button} type="link">
        {t(`mail:addressBar.types.${type}`)}
      </Button>
      <AddressSelectMail
        changeHandler={changeHandler}
        value={value}
        focus={focus}
        projectId={projectId}
        maxCount={5}
        closableTags
        tabIndex={tabIndex}
      />
      {!isMe && (
        <>
          <Button
            type="link"
            onClick={() => {
              dispatch(fetchProjectDistribustionList(projectId));
              setSelectedDistributionLists([]);
              setModalVisible(true);
            }}
            iconProp={['fal', 'clipboard-list']}
            style={{ fontSize: '16px' }}
          ></Button>
          <Modal
            title={t('mail:modals.distributionLists.title')}
            okText={t('mail:modals.distributionLists.okText')}
            cancelText={t('mail:modals.distributionLists.cancelText')}
            visible={debouncedModalVisible}
            width={'50%'}
            onOk={handleOnOkDistributionList}
            okButtonProps={{ disabled: selectedDistributionLists.length === 0 }}
            onCancel={handleOnCancelDistributionList}
            className={classes.modal}
          >
            <ProjectDistributionListTable
              distributionLists={projectDistributionLists}
              setSelectedTableItems={setSelectedDistributionLists}
              selectedTableItems={selectedDistributionLists}
              onRowClick={handleOnRow}
              className={classes.distributionList}
              showBulkActions={false}
            />
          </Modal>
        </>
      )}
    </Flex.Row>
  );
};

export default AddressBar;
